import { useState, useEffect, useContext } from "react";
import withOrdersLogic from "#components/HOC/withOrdersLogic";
import PageTitle from "#components/utils/PageTitle";
import OrdersList from "#components/orders/OrdersList";
import AuditLog from "#components/orders/AuditLog";
import ExpandedOrder from "#components/orders/expandedOrder/ExpandedOrder";
import Modal from "#components/utils/Modal";
import moment from "moment-timezone";
import _ from "lodash";
import CombinedOrdersForm from "#components/orders/CombinedOrdersForm";
import ManualBatch from "#components/orders/batching/ManualBatch";
import BatchDateRangeForm from "#components/orders/BatchDateRangeForm";
import Pagination from "#components/common/Pagination";
import OrderFilters from "#components/orders/OrderFilters";
import {
  UserAddIcon,
  ExclamationIcon,
  DownloadIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import OrderTrackingForm from "#components/orders/OrderTrackingForm";
import OrderCarrierForm from "#components/orders/OrderCarrierForm";
import AddButton from "#components/utils/AddButton";
import ExpandedShippingAddress from "#components/orders/ExpandedShippingAddress";
import BolDoc from "#components/common/BolDoc";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import OrderFormWizard from "#components/orders/OrderFormWizard";
import EditShipmentPlan from "../components/orders/EditShipmentPlan";
import FnSkuLabelOptions from "#components/orders/FnSkuLabelOptions";
import AssignWarehouseForm from "../components/orders/AssignWarehouseForm";
import Checkbox from "#components/utils/Checkbox";
import ImportFbaShipment from "#components/orders/ImportFbaShipment";
import BatchSettings from "#components/orders/BatchSettings";
import Popover from "#components/utils/Popover";
import { OrderSource } from "../utils/enums/orderSourceEnum";
import OrderForm from "#components/orders/OrderForm";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import DateRangeErrorModal from "#components/consignments/DateRangeErrorModal";
import dayjs from "dayjs";
import ExportBanner from "#components/consignments/ExportBanner";
import { useQuery } from "#hooks/useQuery";
import { GET_EXPORT_REPORT } from "#queries/index";
import { ModalContext } from "#components/bulkUpload/useReducer";
import ConsignmentModal from "#components/consignments/ConsignmentModal";
import OrdersModal from "#components/orders/OrdersModal";

const Orders = ({
  mostRecentOrderId,
  newOrders,
  newOrdersAvailable,
  setNewOrdersAvailable,
  auditOrderId,
  setAuditOrderId,
  writable,
  masterData,
  onChangeFilter,
  filters,
  loading,
  selectedAudit,
  setSelectedAudit,
  selectedAuditUsersInfo,
  setSelectedAuditUsersInfo,
  showAudit,
  setShowAudit,
  getAudit,
  selectOrder,
  selectedOrders,
  clearSelectedOrders,
  quickCreateManualBatch,
  simulateAutoBatching,
  simulatedBatches,
  clearSimulatedBatches,
  calculateShippingRates,
  setSelectedRates,
  total,
  newlyAddedOrders,
  pageNumber,
  checkPagination,
  perPage,
  displayOrders,
  runCombinedOrders,
  expandOrder,
  expandedOrderInfo,
  setExpandedOrderInfo,
  selectedCombinedOrder,
  setShowCombinedOrders,
  onChangeCombinedDropdown,
  setPerPage,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  sort,
  setSort,
  showFilters,
  setShowFilters,
  clearFilters,
  runAutoBatching,
  runAutoBatches,
  createManualBatch,
  selectedPicker,
  setSelectedPicker,
  createManualBatchFinal,
  pickers,
  stations,
  printShippingLabel,
  downloadShippingLabel,
  markCompleted,
  markPartialCompleted,
  markInProcess,
  markUnprocessed,
  markCancelled,
  batchDateRange,
  setBatchDateRange,
  resetAllData,
  viewShipmentPlan,
  shipmentPlan,
  setShipmentPlan,
  customers,
  warehouses,
  addNewOrder,
  selectedOrder,
  setSelectedOrder,
  selectedOrderLineItem,
  setSelectedOrderLineItem,
  updateOrderLineItem,
  onChangeSelectedOrderLineItem,
  onChange,
  onChangeDropdown,
  handleOrderDataSubmission,
  addItem,
  removeItem,
  onChangeItem,
  availableInventory,
  onChangeInventorySearch,
  deliveryInfo,
  setDeliveryInfo,
  saveDeliveryInfo,
  removeDelivery,
  updateTracking,
  updateCarrier,
  trackingUpdate,
  setTrackingUpdate,
  submitTrackingUpdate,
  carrierUpdate,
  setCarrierUpdate,
  submitCarrierUpdate,
  viewShippingAddress,
  shippingAddress,
  setShippingAddress,
  getMarketplaceProducts,
  printBol,
  downloadBol,
  tenant,
  bolDoc,
  setBolDoc,
  carrierList,
  carrierServiceList,
  uploadFile,
  deleteOrder,
  downloadOrderReport,
  fileIndex,
  setFileIndex,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  uploadOrders,
  cancelOrders,
  selectAllRows,
  allRowsSelected,
  shipstationList,
  validate,
  validationResult,
  eHubList,
  downloadFnSkuLabels,
  printFnSkuLabels,
  fnSkuOptions,
  setFnSkuOptions,
  submitFnSkuOptions,
  handleAssignWarehouse,
  assignWarehouse,
  selectedWarehouse,
  setSelectedWarehouse,
  cannotChangeOrderWarehouse,
  availableWarehousesForChange,
  editOrder,
  nonEditableFieldsOfOrder,
  isOrderBeingCreated,
  isOrderBeingEdited,
  setOrderBeingCreated,
  setOrderBeingEdited,
  setOrderToMarkComplete,
  orderToMarkComplete,
  confirmMarkCompleted,
  markCompleteOnMarketplaceCheck,
  setMarkCompleteOnMarketplaceCheck,
  markShippedOnMarketplace,
  shipmentImportSearch,
  setShipmentImportSearch,
  onImportShipmentSearch,
  importShipment,
  setImportShipment,
  importFbaShipmentModal,
  setImportFbaShipmentModal,
  confirmImportShipment,
  onChangeDropdownImport,
  importFilters,
  setImportFilters,
  orderToMarkCancelled,
  setOrderToMarkCancelled,
  setMarkCancelledOnMarketplaceCheck,
  confirmMarkCancelled,
  possibleSubStatuses,
  showOnlySelected,
  setShowOnlySelected,
  showBatchSettings,
  setShowBatchSettings,
  batchSettingsEnabled,
  batchSettings,
  setBatchSettings,
  saveBatchSettings,
  fetchProductSkuBinMappings,
  productSkuBinMappings,
  lotIdSelectionEnabled,
  orders,
  manualBatchConfig,
  setManualBatchConfig,
  bolUploadEnabled,
  multiAccountSupportEnabled,
  marketplaces,
  sellerIds,
  downloadBoxLabels,
  printBoxLabels,
  downloadPalletLabels,
  printPalletLabels,
  updateCarrierInfoQuery,
  dataTableFilters,
  printPackingLabels,
  downloadPackingLabel,
  packingLabelsEnabled,
  orderChangesPreview,
  setOrderChangesPreview,
  downloadPackingList,
  csvPackingListEnabled,
  productSkuBinMappingsWithLpn,
  productSkuBinMappingsWithSerialNumber,
  productSkuBinMappingsWithPalletId,
  lpnSelectionEnabled,
  printOrder,
  isBackOrderEnabledForTenant,
  showBatchingPreview,
  ordersFulfillmentDetails,
  setOrdersFulfillmentDetails,
  groupedOrders,
  selectedOrderNotes,
  setSelectedOrderNotes,
  isManualBatchExecuting,
  viewInvoiceLabels,
  selectedSalesOrder,
  setSelectedSalesOrder,
  addNewSalesOrder,
  onChangeSalesDropdown,
  cart,
  setCart,
  handleSalesOrderSubmission,
  newCustomer,
  setNewCustomer,
  createCRMCustomer,
  crmCustomers,
  handleAddToCart,
  getAvalaraTaxInvoice,
  avalaraTaxInvoice,
  salesOrderMode,
  setSalesOrderMode,
  salesOrderModesEnum,
  addCustomerPanel,
  setAddCustomerPanel,
  salesOrderIds,
  setSalesOrderIds,
  customersSelectedForReport,
  setCustomersSelectedForReport,
}) => {
  const ldClient = useLDClient();

  const [orderLineItems, setOrderLineItems] = useState(null);
  const showOrderFormWizard = isOrderBeingCreated || isOrderBeingEdited;

  const createSalesOrderEnabled = ldClient.variation(
    featureFlags.SALES_PORTAL_ENABLED,
  );

  const actionsOnPopover = [
    {
      name: "Regular",
      value: "create",
      onClick: () => {
        addNewOrder("Regular");
      },
    },
  ];

  const manageActionTypes = [
    { name: "Export Report", value: "EXPORT_REPORT" },
    { name: "Download Template", value: "DOWNLOAD_TEMPLATE" },
    { name: "Upload File", value: "UPLOAD_FILE" },
  ];

  if (tenant?.typeOfCustomer?.includes("Prep Center")) {
    manageActionTypes.push({
      name: "Import FBA Shipment",
      value: "IMPORT_FBA_SHIPMENT",
    });
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [showNow, setShowNow] = useState(false);
  const [bannerMessage, setBannerMessage] = useState(
    "Your report has been scheduled for generation. You will be notified when it is ready for download",
  );
  const [showYellowBanner, setShowYellowBanner] = useState(true);
  const [showGreenBanner, setShowGreenBanner] = useState(false);
  const [showRedBanner, setShowRedBanner] = useState(false);
  const [reportUrl, setReportUrl] = useState("");
  const [showUploadBulk, setShowUploadBulk] = useState(false);
  const modals = useContext(ModalContext);

  const generatedReportUrl = localStorage.getItem("EXPORT_ORDERS_REPORT_URL");
  const generatedReportId = localStorage.getItem("EXPORT_ORDERS_REPORT_ID");
  const getReportPollQuery = useQuery(GET_EXPORT_REPORT);
  const POLLING_TIMER = 2000;

  useEffect(() => {
    if (generatedReportId && displayOrders.length > 0) {
      setShowYellowBanner(true);
      setShowGreenBanner(false);
      setShowRedBanner(false);
      setShowNow(true);
      setBannerMessage(
        "Your report has been scheduled for generation. You will be notified when it is ready for download",
      );
      startReportPolling(generatedReportId);
    } else if (generatedReportUrl && displayOrders.length > 0) {
      setReportUrl(generatedReportUrl);
      setShowYellowBanner(false);
      setShowGreenBanner(true);
      setShowNow(true);
      if (filters?.startEndDate && filters?.startEndDate[0]) {
        setBannerMessage("Your report is ready for download.");
      } else {
        setBannerMessage(
          "The report of orders from the past 12 months is ready.",
        );
      }
    }
  }, [generatedReportUrl, displayOrders]);

  const startReportPolling = (reportId) => {
    const autoRefreshInterval = setInterval(async () => {
      try {
        const getReportResponse = await getReportPollQuery.fetchData({
          reportId: reportId,
        });

        if (getReportResponse.data.getReport.status === "GENERATED") {
          setReportUrl(getReportResponse.data.getReport.csvUrl);
          localStorage.setItem("EXPORT_ORDERS_REPORT_ID", "");
          localStorage.setItem(
            "EXPORT_ORDERS_REPORT_URL",
            getReportResponse.data.getReport.csvUrl,
          );
          clearInterval(autoRefreshInterval);
          setShowYellowBanner(false);
          setShowGreenBanner(true);
          setShowRedBanner(false);
          setShowNow(true);
          if (filters?.startEndDate && filters?.startEndDate[0]) {
            setBannerMessage("Your report is ready for download.");
          } else {
            setBannerMessage(
              "The report of orders from the past 12 months is ready.",
            );
          }
        } else if (
          getReportResponse.data.getReport.status === "GENERATION_FAILED"
        ) {
          clearInterval(autoRefreshInterval);
          setShowYellowBanner(false);
          setShowRedBanner(true);
          setShowGreenBanner(false);
          setShowNow(true);
          setBannerMessage("Download failed");
        }
      } catch (error) {
        clearInterval(autoRefreshInterval);
        setShowYellowBanner(false);
        setShowRedBanner(true);
        setShowGreenBanner(false);
        setShowNow(true);
        setBannerMessage("An error occurred while fetching the report.");
      }
    }, POLLING_TIMER);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openErrorModal = () => {
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const openBanner = () => {
    setShowNow(true);
  };

  const closeBanner = () => {
    setShowNow(false);
  };

  const handleManageActionClick = (actionType) => {
    switch (actionType) {
      case "EXPORT_REPORT":
        handleExportReportClick();
        setShowNow(false);
        break;
      case "IMPORT_FBA_SHIPMENT":
        if (tenant?.typeOfCustomer?.includes("Prep Center")) {
          setImportFbaShipmentModal(true);
        }
        break;
      case "DOWNLOAD_TEMPLATE":
        window.location =
          "https://templates-onboarding.s3.amazonaws.com/prod/Orders.xlsx";
        break;
      case "UPLOAD_FILE":
        modals.startUploading();
        break;
      default:
        break;
    }
  };

  const handleDateRangeChange = (range) => {
    setDateRange({ startDate: range[0], endDate: range[1] });
  };

  const validateDateRange = () => {
    const { startDate, endDate } = dateRange;

    if (startDate && endDate) {
      const start = dayjs(startDate, "DD-MM-YYYY");
      const end = dayjs(endDate, "DD-MM-YYYY");

      const diffDays = end.diff(start, "day");

      if (diffDays > 90) {
        openErrorModal();
        return false;
      }
    }
    return true;
  };

  const handleExportReportClick = () => {
    openModal();
  };

  if (tenant?.settings?.isSTOEnabled === true) {
    actionsOnPopover.push({
      name: "Stock Transfer",
      value: "create-sto",
      onClick: () => {
        addNewOrder("STO");
      },
    });
  }
  return (
    <div className="p-5">
      <div className="flex items-center">
        <div className="mb-20 w-full">
          <div className="flex flex-wrap items-center pb-4">
            <div className="flex-1">
              <PageTitle>Order Management({total} orders)</PageTitle>
            </div>

            <div className="flex items-center justify-end space-x-2">
              {writable && (
                <>
                  <Popover
                    title={"Actions"}
                    showChevron={true}
                    panelClassName={
                      "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1  border border-borderGray w-full"
                    }
                    showOverlay={true}
                    buttonClassName="flex items-center justify-center font-medium gap-x-1 w-full bg-primaryAccent text-white rounded-lg py-2 px-7 cursor-pointer">
                    {manageActionTypes.map((actionType, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                          onClick={() =>
                            handleManageActionClick(actionType.value)
                          }>
                          {actionType.name}
                        </div>
                      );
                    })}
                  </Popover>
                  <Popover
                    title={"Create Order"}
                    showChevron={true}
                    panelClassName={
                      "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray w-full"
                    }
                    showOverlay={true}>
                    {actionsOnPopover.map((action) => {
                      return (
                        <div
                          className="w-full cursor-pointer rounded-lg p-3 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                          onClick={
                            action.onClick
                              ? action.onClick
                              : () => console.log(action.value, action.name)
                          }>
                          {action.name}
                        </div>
                      );
                    })}
                  </Popover>

                  {createSalesOrderEnabled && (
                    <AddButton
                      text="Create Sales Order"
                      onClick={addNewSalesOrder}
                      icon={PlusIcon}
                    />
                  )}

                  <UploadBulk
                    dashboardFields={dashboardFields}
                    saveBulkUpload={saveBulkUpload}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    validate={validate}
                    validationResult={validationResult}
                    showAddButton={false}
                  />
                </>
              )}
            </div>
          </div>
          {showNow && (
            <ExportBanner
              message={bannerMessage}
              onClose={closeBanner}
              istrue={true}
              setShowYellowBanner={showYellowBanner}
              setShowGreenBanner={showGreenBanner}
              setShowRedBanner={showRedBanner}
              reportUrl={reportUrl}
            />
          )}

          <OrdersList
            writable={writable}
            customers={customers}
            orders={displayOrders}
            masterData={masterData}
            onChangeFilter={onChangeFilter}
            newlyAddedOrders={newlyAddedOrders}
            filters={filters}
            mostRecentOrderId={mostRecentOrderId}
            newOrders={newOrders}
            newOrdersAvailable={newOrdersAvailable}
            setNewOrdersAvailable={setNewOrdersAvailable}
            loading={loading}
            getAudit={getAudit}
            selectOrder={selectOrder}
            selectedOrders={selectedOrders}
            clearSelectedOrders={clearSelectedOrders}
            quickCreateManualBatch={quickCreateManualBatch}
            createManualBatch={createManualBatch}
            simulateAutoBatching={simulateAutoBatching}
            calculateShippingRates={calculateShippingRates}
            setSelectedRates={setSelectedRates}
            runCombinedOrders={() => setShowCombinedOrders(true)}
            expandOrder={expandOrder}
            onChangeSearchKeyword={onChangeSearchKeyword}
            submitFilters={submitFilters}
            clearKeyword={clearKeyword}
            sort={sort}
            setSort={setSort}
            setShowFilters={setShowFilters}
            clearFilters={clearFilters}
            runAutoBatching={runAutoBatching}
            printShippingLabel={printShippingLabel}
            downloadShippingLabel={downloadShippingLabel}
            viewInvoiceLabels={viewInvoiceLabels}
            markInProcess={markInProcess}
            markUnprocessed={markUnprocessed}
            markCompleted={markCompleted}
            markCancelled={markCancelled}
            markPartialCompleted={markPartialCompleted}
            resetAllData={resetAllData}
            viewShipmentPlan={viewShipmentPlan}
            updateTracking={updateTracking}
            updateCarrier={updateCarrier}
            viewShippingAddress={viewShippingAddress}
            printBol={printBol}
            downloadBol={downloadBol}
            tenant={tenant}
            deleteOrder={deleteOrder}
            downloadOrderReport={downloadOrderReport}
            cancelOrders={cancelOrders}
            selectAllRows={selectAllRows}
            allRowsSelected={allRowsSelected}
            downloadFnSkuLabels={downloadFnSkuLabels}
            printFnSkuLabels={printFnSkuLabels}
            handleAssignWarehouse={handleAssignWarehouse}
            editOrder={editOrder}
            markShippedOnMarketplace={markShippedOnMarketplace}
            possibleSubStatuses={possibleSubStatuses}
            showOnlySelected={showOnlySelected}
            setShowOnlySelected={setShowOnlySelected}
            showBatchSettings={showBatchSettings}
            setShowBatchSettings={setShowBatchSettings}
            batchSettingsEnabled={batchSettingsEnabled}
            warehouses={warehouses}
            bolUploadEnabled={bolUploadEnabled}
            downloadBoxLabels={downloadBoxLabels}
            printBoxLabels={printBoxLabels}
            downloadPalletLabels={downloadPalletLabels}
            printPalletLabels={printPalletLabels}
            total={total}
            dataTableFilters={dataTableFilters}
            printPackingLabels={printPackingLabels}
            downloadPackingLabel={downloadPackingLabel}
            packingLabelsEnabled={packingLabelsEnabled}
            csvPackingListEnabled={csvPackingListEnabled}
            downloadPackingList={downloadPackingList}
            printOrder={printOrder}
            groupedOrders={groupedOrders}
            selectedOrderNotes={selectedOrderNotes}
            setSelectedOrderNotes={setSelectedOrderNotes}
            salesOrderMode={salesOrderMode}
            setSalesOrderMode={setSalesOrderMode}
            salesOrderModesEnum={salesOrderModesEnum}
            salesOrderIds={salesOrderIds}
            setSalesOrderIds={setSalesOrderIds}
            handleDateRange={handleDateRangeChange}
            perPage={perPage}
          />

          {selectedWarehouse && (
            <AssignWarehouseForm
              onClose={() => setSelectedWarehouse(null)}
              title={"Assign Warehouse"}
              onSubmit={assignWarehouse}
              warehouses={availableWarehousesForChange}
              setSelectedWarehouse={setSelectedWarehouse}
              selectedWarehouse={selectedWarehouse}
              cannotChangeOrderWarehouse={cannotChangeOrderWarehouse}
            />
          )}
        </div>
      </div>
      {!showOnlySelected && (
        <Pagination
          showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
          showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
          showingTotal={total}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
        />
      )}
      {showFilters && (
        <OrderFilters
          negativeAction={() => setShowFilters(false)}
          masterData={masterData}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          warehouses={warehouses}
          clearFilters={clearFilters}
          possibleSubStatuses={possibleSubStatuses}
          tenant={tenant}
          submitFilters={submitFilters}
        />
      )}
      {importFbaShipmentModal && (
        <ImportFbaShipment
          shipmentPlan={importShipment}
          negativeAction={() => {
            setImportFbaShipmentModal(false);
            setImportShipment(null);
            setImportFilters({});
            setShipmentImportSearch(null);
          }}
          onSubmit={confirmImportShipment}
          onImportShipmentSearch={onImportShipmentSearch}
          shipmentImportSearch={shipmentImportSearch}
          setShipmentImportSearch={setShipmentImportSearch}
          warehouses={warehouses}
          customers={customers}
          onChangeDropdown={onChangeDropdownImport}
          importFilters={importFilters}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          marketplaces={marketplaces}
          sellerIds={sellerIds}
        />
      )}
      {showBatchSettings && (
        <BatchSettings
          showBatchSettings={showBatchSettings}
          setShowBatchSettings={setShowBatchSettings}
          batchSettings={batchSettings}
          setBatchSettings={setBatchSettings}
          saveBatchSettings={saveBatchSettings}
        />
      )}

      {selectedSalesOrder ||
      salesOrderMode === salesOrderModesEnum.CREATE ||
      (salesOrderMode === salesOrderModesEnum.VIEW &&
        salesOrderIds.length > 0) ? (
        <OrderForm
          selectedConsignment={selectedSalesOrder}
          setSelectedConsignment={setSelectedSalesOrder}
          onChange={onChange}
          onChangeDropdown={onChangeSalesDropdown}
          title={
            salesOrderMode === salesOrderModesEnum.VIEW
              ? "View Sales Order"
              : "Add Sales Order"
          }
          saveConsignment={() => {}}
          customers={customers}
          warehouses={warehouses}
          removeItem={removeItem}
          submitAddedProduct={() => {}}
          saveConsignmentDraft={() => {}}
          setDropshipData={() => {}}
          dropshipData={() => {}}
          uploadFile={() => {}}
          tenant={tenant}
          casePackEnabled={false}
          multiAccountSupportEnabled={false}
          refreshConsignmentItems={() => {}}
          cart={cart}
          setCart={setCart}
          handleSalesOrderSubmission={handleSalesOrderSubmission}
          newCustomer={newCustomer}
          setNewCustomer={setNewCustomer}
          createCRMCustomer={createCRMCustomer}
          crmCustomers={crmCustomers}
          handleAddToCart={handleAddToCart}
          getAvalaraTaxInvoice={getAvalaraTaxInvoice}
          avalaraTaxInvoice={avalaraTaxInvoice}
          salesOrderMode={salesOrderMode}
          setSalesOrderMode={setSalesOrderMode}
          salesOrderModesEnum={salesOrderModesEnum}
          addCustomerPanel={addCustomerPanel}
          setAddCustomerPanel={setAddCustomerPanel}
          salesOrderIds={salesOrderIds}
          setSalesOrderIds={setSalesOrderIds}
        />
      ) : null}

      {showOrderFormWizard && (
        <OrderFormWizard
          selectedOrder={selectedOrder}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title="Add Order"
          onClose={() => {
            setSelectedOrder(null);
            setOrderBeingCreated(false);
            setOrderBeingEdited(false);
          }}
          onSubmit={handleOrderDataSubmission}
          customers={customers}
          warehouses={warehouses}
          addItem={addItem}
          removeItem={removeItem}
          onChangeItem={onChangeItem}
          availableInventory={availableInventory}
          onChangeInventorySearch={onChangeInventorySearch}
          deliveryInfo={deliveryInfo}
          setDeliveryInfo={setDeliveryInfo}
          saveDeliveryInfo={saveDeliveryInfo}
          removeDelivery={removeDelivery}
          getMarketplaceProducts={getMarketplaceProducts}
          carrierList={carrierList}
          carrierServiceList={carrierServiceList}
          tenant={tenant}
          shipstationList={shipstationList}
          eHubList={eHubList}
          setSelectedOrder={setSelectedOrder}
          nonEditableFieldsOfOrder={
            isOrderBeingEdited ? nonEditableFieldsOfOrder : []
          }
          selectOrder={selectOrder}
          isOrderBeingEdited={isOrderBeingEdited}
          fetchProductSkuBinMappings={fetchProductSkuBinMappings}
          productSkuBinMappings={productSkuBinMappings}
          lotIdSelectionEnabled={lotIdSelectionEnabled}
          lpnSelectionEnabled={lpnSelectionEnabled}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          productSkuBinMappingsWithLpn={productSkuBinMappingsWithLpn}
          productSkuBinMappingsWithSerialNumber={
            productSkuBinMappingsWithSerialNumber
          }
          productSkuBinMappingsWithPalletId={productSkuBinMappingsWithPalletId}
        />
      )}
      {trackingUpdate && (
        <OrderTrackingForm
          trackingUpdate={trackingUpdate}
          setTrackingUpdate={setTrackingUpdate}
          title="Update Shipping Information"
          onClose={() => setTrackingUpdate(null)}
          onSubmit={submitTrackingUpdate}
          uploadFile={uploadFile}
          bolUploadEnabled={bolUploadEnabled}
        />
      )}
      {orderToMarkComplete && (
        <CompleteOrder
          expandedOrderInfo={expandedOrderInfo}
          setExpandedOrderInfo={setExpandedOrderInfo}
          setOrderToMarkComplete={setOrderToMarkComplete}
          setMarkCompleteOnMarketplaceCheck={setMarkCompleteOnMarketplaceCheck}
          confirmMarkCompleted={confirmMarkCompleted}
          orderChangesPreview={orderChangesPreview}
          markCompleteOnMarketplaceCheck={markCompleteOnMarketplaceCheck}
        />
      )}
      {orderToMarkCancelled && (
        <CancelOrder
          expandedOrderInfo={expandedOrderInfo}
          setExpandedOrderInfo={setExpandedOrderInfo}
          setOrderToMarkCancelled={setOrderToMarkCancelled}
          setMarkCancelledOnMarketplaceCheck={
            setMarkCancelledOnMarketplaceCheck
          }
          confirmMarkCancelled={confirmMarkCancelled}
          orderChangesPreview={orderChangesPreview}
        />
      )}
      {carrierUpdate &&
        (tenant?.features?.rateShopping === true ? (
          <OrderFormWizard
            selectedOrder={selectedOrder}
            onChange={onChange}
            onChangeDropdown={onChangeDropdown}
            title="Add Order"
            onClose={() => {
              setSelectedOrder(null);
              setOrderBeingCreated(false);
              setOrderBeingEdited(false);
              setCarrierUpdate(null);
            }}
            onSubmit={() => {
              updateCarrierInfoQuery.fetchData({
                orderInfo: {
                  ...carrierUpdate,
                  ...(selectedOrder?.selectedCarrierRateData || {}),
                  shippingAddress: selectedOrder?.shippingAddress,
                  toValidAddress: selectedOrder?.toValidAddress,
                  insuranceRequired: selectedOrder?.insuranceRequired,
                  estimatedBoxes: selectedOrder?.estimatedBoxes,
                },
              });
            }}
            customers={customers}
            warehouses={warehouses}
            addItem={addItem}
            removeItem={removeItem}
            onChangeItem={onChangeItem}
            availableInventory={availableInventory}
            onChangeInventorySearch={onChangeInventorySearch}
            deliveryInfo={deliveryInfo}
            setDeliveryInfo={setDeliveryInfo}
            saveDeliveryInfo={saveDeliveryInfo}
            removeDelivery={removeDelivery}
            getMarketplaceProducts={getMarketplaceProducts}
            carrierList={carrierList}
            carrierServiceList={carrierServiceList}
            tenant={tenant}
            shipstationList={shipstationList}
            eHubList={eHubList}
            setSelectedOrder={setSelectedOrder}
            nonEditableFieldsOfOrder={
              isOrderBeingEdited ? nonEditableFieldsOfOrder : []
            }
            selectOrder={selectOrder}
            isOrderBeingEdited={!!carrierUpdate}
            fetchProductSkuBinMappings={fetchProductSkuBinMappings}
            productSkuBinMappings={productSkuBinMappings}
            productSkuBinMappingsWithSerialNumber={
              productSkuBinMappingsWithSerialNumber
            }
            productSkuBinMappingsWithPalletId={
              productSkuBinMappingsWithPalletId
            }
            lotIdSelectionEnabled={lotIdSelectionEnabled}
            displayedTabs={["Specify Address", "Box Dimensions", "Tracking"]}
            showTrackingNumberInput={false}
          />
        ) : (
          <OrderCarrierForm
            carrierUpdate={carrierUpdate}
            setCarrierUpdate={setCarrierUpdate}
            title="Update Carrier Information"
            onClose={() => setCarrierUpdate(null)}
            onSubmit={submitCarrierUpdate}
            carrierList={carrierList}
            carrierServiceList={carrierServiceList}
            tenant={tenant}
            shipstationList={shipstationList}
            eHubList={eHubList}
          />
        ))}
      {batchDateRange && (
        <BatchDateRangeForm
          negativeAction={() => setBatchDateRange(null)}
          onSubmit={runAutoBatches}
          batchDateRange={batchDateRange}
          onChange={(field, value) =>
            setBatchDateRange({
              ...batchDateRange,
              [field]: value,
            })
          }
        />
      )}
      {manualBatchConfig && (
        <ManualBatch
          createManualBatchFinal={createManualBatchFinal}
          negativeAction={() => {
            setManualBatchConfig(null);
            setOrdersFulfillmentDetails(null);
            submitFilters();
          }}
          manualBatchConfig={manualBatchConfig}
          setManualBatchConfig={setManualBatchConfig}
          pickers={pickers}
          stations={stations}
          orders={orders}
          selectedOrderIds={selectedOrders}
          tenant={tenant}
          batchSettings={batchSettings}
          ordersFulfillmentDetails={ordersFulfillmentDetails}
          isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
          showBatchingPreview={showBatchingPreview}
          isManualBatchExecuting={isManualBatchExecuting}
        />
      )}
      {selectedCombinedOrder && (
        <CombinedOrdersForm
          negativeAction={() => setShowCombinedOrders(null)}
          selectedCombinedOrder={selectedCombinedOrder}
          onChangeDropdown={onChangeCombinedDropdown}
          onSubmit={runCombinedOrders}
        />
      )}
      {expandedOrderInfo && !orderToMarkComplete && !orderToMarkCancelled && (
        <ExpandedOrder
          writable={writable}
          expandedOrderInfo={expandedOrderInfo}
          negativeAction={() => setExpandedOrderInfo(null)}
          selectedOrderLineItem={selectedOrderLineItem}
          setSelectedOrderLineItem={setSelectedOrderLineItem}
          updateOrderLineItem={updateOrderLineItem}
          onChangeSelectedOrderLineItem={onChangeSelectedOrderLineItem}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          customers={customers}
          warehouses={warehouses}
          tenant={tenant}
          packingLabelsEnabled={packingLabelsEnabled}
          printFnSkuLabels={printFnSkuLabels}
          downloadFnSkuLabels={downloadFnSkuLabels}
          printPackingLabels={printPackingLabels}
          downloadPackingLabel={downloadPackingLabel}
          csvPackingListEnabled={csvPackingListEnabled}
          downloadPackingList={downloadPackingList}
          getAudit={getAudit}
          downloadOrderReport={downloadOrderReport}
          printPalletLabels={printPalletLabels}
          downloadPalletLabels={downloadPalletLabels}
          printBoxLabels={printBoxLabels}
          bolUploadEnabled={bolUploadEnabled}
          downloadBol={downloadBol}
          printBol={printBol}
          downloadBoxLabels={downloadBoxLabels}
          printShippingLabel={printShippingLabel}
          downloadShippingLabel={downloadShippingLabel}
          printOrder={printOrder}
          cancelOrders={cancelOrders}
          markCompleted={markCompleted}
        />
      )}
      {shipmentPlan && (
        <EditShipmentPlan
          toEditShipmentPlan={shipmentPlan}
          changeShipmentPlan={setShipmentPlan}
          negativeAction={() => setShipmentPlan(null)}
        />
      )}
      {shippingAddress && (
        <ExpandedShippingAddress
          shippingAddress={shippingAddress}
          negativeAction={() => setShippingAddress(null)}
        />
      )}
      {showAudit && (
        <AuditLog
          auditOrderId={auditOrderId}
          selectedAudit={selectedAudit}
          selectedAuditUsersInfo={selectedAuditUsersInfo}
          negativeAction={() => {
            setShowAudit(false);
            setSelectedAudit(null);
            setSelectedAuditUsersInfo(null);
            setAuditOrderId(null);
          }}
        />
      )}
      {simulatedBatches.length > 0 && (
        <Modal
          negativeAction={clearSimulatedBatches}
          title={`Simulated Batches (${simulatedBatches.length})`}>
          <table className="min-w-full divide-y divide-gray-200 whitespace-nowrap">
            <thead className="rounded-full p-4 pr-5">
              <tr className="font-montserratborder-left bg-primaryAccent text-white">
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Date
                </th>
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  # Items
                </th>
                <th
                  scope="col"
                  className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                  Row #s
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 pl-4 text-left font-medium tracking-wider">
                  Items
                </th>
              </tr>
            </thead>
            <tbody>
              {simulatedBatches.map((batch, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {moment(batch.createdAt).format("MMM Do YYYY, h:mm a")}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {batch.items.length}
                  </td>

                  <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {batch.rows?.join(",")}
                  </td>
                  <td className="px-1 py-1 pl-4 text-left font-bold tracking-wider text-5F666B">
                    <div
                      onClick={() => setOrderLineItems(batch.items)}
                      className="cursor-pointer">
                      View
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {orderLineItems && orderLineItems.length > 0 && (
            <Modal
              negativeAction={() => setOrderLineItems(null)}
              title={`Order Line Items(${orderLineItems.length})`}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="rounded-full p-4">
                  <tr className="font-montserratborder-left bg-primaryAccent text-white">
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                      SKU
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                      Collection
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                      Color
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                      Size
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderLineItems.map((orderLineItem, index) => (
                    <tr
                      key={orderLineItem._id}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}>
                      <td
                        className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                          index % 2 === 0
                            ? "border-F4C261"
                            : "border-primaryAccent"
                        }`}>
                        {orderLineItem.sku}
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        {orderLineItem.attributes?.collection}
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        {orderLineItem.attributes?.color}
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        {orderLineItem.attributes?.size}
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        {orderLineItem.quantity}
                      </td>
                      <td className="px-1 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        ${_.round(orderLineItem.price, 2).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal>
          )}
        </Modal>
      )}
      {selectedOrderNotes && (
        <Modal
          title={`Order Notes`}
          negativeAction={() => setSelectedOrderNotes(null)}>
          <table className="divide-y divide-gray-200 border border-gray-400 px-2">
            <thead className="rounded-full bg-primaryAccent p-4 px-12">
              <tr className="border-left px-12 font-montserrat text-textWhite">
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                  Notes
                </th>
                <th
                  scope="col"
                  className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedOrderNotes.map((item, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-semibold text-primaryAccent ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {`#${index + 1}`}
                  </td>

                  <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {item.split(", ").map((i) => (
                      <>
                        <div key={i}>{i}</div>
                      </>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}

      {bolDoc && (
        <div className="fixed">
          <BolDoc
            id={bolDoc.orderId}
            date={moment().format("MM/DD/YYYY")}
            pickupDate={moment(
              bolDoc.dateOfShipping
                ? bolDoc.dateOfShipping
                : bolDoc.completedDate,
            ).format("MM/DD/YYYY")}
            deliveryDate={moment(
              bolDoc.deliveryDate ? bolDoc.deliveryDate : bolDoc.completedDate,
            ).format("MM/DD/YYYY")}
            orderNo={bolDoc.orderId}
            weight={_.sumBy(bolDoc.orderLineItems, "attributes.weight")}
            orderLineItems={bolDoc.orderLineItems}
            shippingAddress={bolDoc.shippingAddress}
            tenant={tenant}
            order={bolDoc}
            thirdPartyFreightChargesBill={
              bolDoc.thirdPartyFreightChargesBill || ""
            }
            carrier={bolDoc.carrier}
          />
        </div>
      )}
      {fnSkuOptions && (
        <FnSkuLabelOptions
          fnSkuLabelOptions={fnSkuOptions}
          setFnSkuLabelOptions={setFnSkuOptions}
          positiveAction={submitFnSkuOptions}
        />
      )}
      {isModalOpen && (
        <OrdersModal
          show={true}
          setShowNow={setShowNow}
          onClose={closeModal}
          setCustomersSelectedForReport={setCustomersSelectedForReport}
          customersSelectedForReport={customersSelectedForReport}
          filters={filters}
          setShowYellowBanner={setShowYellowBanner}
          setShowGreenBanner={setShowGreenBanner}
          setShowRedBanner={setShowRedBanner}
          setBannerMessage={setBannerMessage}
          setReportUrl={setReportUrl}
          startReportPolling={startReportPolling}
          displayOrders={displayOrders}
          customers={customers}
          warehouses={warehouses}
        />
      )}
    </div>
  );
};

const CompleteOrder = ({
  expandedOrderInfo,
  setExpandedOrderInfo,
  setOrderToMarkComplete,
  setMarkCompleteOnMarketplaceCheck,
  confirmMarkCompleted,
  orderChangesPreview,
  markCompleteOnMarketplaceCheck,
}) => {
  const order = expandedOrderInfo?.order;

  return (
    <Modal
      title={`Order ID : ${order?.orderId} `}
      onClose={() => {
        setOrderToMarkComplete(null);
        setMarkCompleteOnMarketplaceCheck(false);
        setExpandedOrderInfo(null);
      }}
      positiveText={"Yes, I confirm"}
      negativeText={"No, Cancel"}
      positiveAction={confirmMarkCompleted}
      negativeAction={() => {
        setOrderToMarkComplete(null);
        setMarkCompleteOnMarketplaceCheck(false);
        setExpandedOrderInfo(null);
      }}>
      <div className="flex flex-col items-center justify-center">
        <ExclamationIcon
          className="h-20 w-20 rounded-full bg-primaryAccent p-4 font-light text-white"
          aria-hidden="true"
        />
        <h1 className="mt-4 text-center font-montserrat text-2xl text-454A4F">
          Are you sure you want to mark this order as COMPLETED?
        </h1>
        {ModalTable(orderChangesPreview)}
        <h1 className="mt-4 text-center font-montserrat text-xl text-454A4F">
          Note: If you manually complete the order, all allocated product
          quantities will be deducted.
        </h1>
        {[
          OrderSource.FBA,
          OrderSource.SHOPIFY,
          OrderSource.SALLA,
          OrderSource.NETSUITE,
          OrderSource.ZID,
          OrderSource.APPARELMAGIC,
          OrderSource.SAP_B1_TYM,
          OrderSource.E_BAY,
        ].includes(order?.source) && (
          <div className="mt-2 flex flex-row justify-center">
            <Checkbox
              name="markShip"
              onChange={() =>
                setMarkCompleteOnMarketplaceCheck(
                  !markCompleteOnMarketplaceCheck,
                )
              }
              checked={markCompleteOnMarketplaceCheck}
            />
            <h1 className="pl-4 text-center font-montserrat text-xl text-454A4F">
              Mark as Shipped on {order?.source}
            </h1>
          </div>
        )}
      </div>
    </Modal>
  );
};

const CancelOrder = ({
  expandedOrderInfo,
  setExpandedOrderInfo,
  setOrderToMarkCancelled,
  setMarkCancelledOnMarketplaceCheck,
  confirmMarkCancelled,
  orderChangesPreview,
}) => {
  const order = expandedOrderInfo?.order;

  return (
    <Modal
      title={`Order ID : ${order?.orderId} `}
      onClose={() => {
        setOrderToMarkCancelled(null);
        setMarkCancelledOnMarketplaceCheck(false);
        setExpandedOrderInfo(null);
      }}
      positiveText={"Yes, I confirm"}
      negativeText={"No, Cancel"}
      positiveAction={confirmMarkCancelled}
      negativeAction={() => {
        setOrderToMarkCancelled(null);
        setMarkCancelledOnMarketplaceCheck(false);
        setExpandedOrderInfo(null);
      }}>
      <div className="flex flex-col items-center justify-center">
        <ExclamationIcon
          className="h-20 w-20 rounded-full bg-primaryAccent p-4 font-light text-white"
          aria-hidden="true"
        />
        <h1 className="mt-4 text-center font-montserrat text-2xl text-454A4F">
          Are you sure you want to mark this order as Cancelled?
        </h1>
        {ModalTable(orderChangesPreview)}
        {["UNPROCESSED", "EXCEPTION"].includes(order?.orderStatus) ===
          false && (
          //This condition is in-place because unprocessed or exception orders
          //won't have stock allocated so there's no need to show the message..
          <h1 className="mt-4 text-center font-montserrat text-xl text-454A4F">
            All allocated stock for this order will be transferred back to
            inventory to the original storage locations from which they were
            allocated. Please ensure it is putaway into the respective storage
            locations.
          </h1>
        )}
        {["FBA"].includes(order?.source) && (
          <div className="mt-2 flex flex-row justify-center">
            <h1 className="pl-4 text-center font-montserrat text-xl text-454A4F">
              NOTE: Any marketplace shipment associated with this order will
              also be cancelled
            </h1>
          </div>
        )}
      </div>
    </Modal>
  );
};

const ModalTable = (orderChangesPreview) => {
  return (
    <div className="mt-4 overflow-x-auto">
      {orderChangesPreview?.batches?.length > 0 && (
        <div>
          <h2 className="mt-2 font-montserrat text-xl text-454A4F">
            Batches Affected
          </h2>
          <table className="mt-2 w-full border-collapse">
            <thead>
              <tr className="bg-454A4F text-white">
                <th className="px-4 py-2">Batch ID</th>
                <th className="px-4 py-2">Type</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">User Name</th>
              </tr>
            </thead>
            <tbody>
              {orderChangesPreview?.batches.map((batch) => (
                <tr key={batch.id} className="hover:bg-gray-200">
                  <td className="border px-4 py-2">{batch.id}</td>
                  <td className="border px-4 py-2">{batch.type}</td>
                  <td className="border px-4 py-2">{batch.status}</td>
                  <td className="border px-4 py-2">{batch.userName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {orderChangesPreview?.skuAudits?.length > 0 && (
        <div>
          <h2 className="mt-4 font-montserrat text-xl text-454A4F">
            Inventory Affected
          </h2>
          <table className="mt-2 w-full border-collapse">
            <thead>
              <tr className="bg-454A4F text-white">
                <th className="px-4 py-2">SKU</th>
                <th className="px-4 py-2">Amount</th>
                <th className="px-4 py-2">Bin Location</th>
              </tr>
            </thead>
            <tbody>
              {orderChangesPreview?.skuAudits.map((audit) => (
                <tr key={audit.id} className="hover:bg-gray-200">
                  <td className="border px-4 py-2">
                    {audit.sku || "No SKU ID found"}
                  </td>
                  <td className="border px-4 py-2">{Math.abs(audit.amount)}</td>
                  <td className="border px-4 py-2">{audit.binLocation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default withOrdersLogic(Orders);
