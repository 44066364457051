import moment from "moment-timezone";
import { isDelmarTenant } from "#utils/tenantCheck";

const MetaInformation = ({
  tenant,
  expandedOrderInfo,
  customers,
  warehouses,
}) => {
  const order = expandedOrderInfo.order;
  const isBackOrder = expandedOrderInfo.isBackOrder;

  return (
    <div className="grid w-full grid-cols-3 gap-4 break-words rounded-xl border border-gray-500 p-3">
      <div className="h-full rounded-lg">
        {renderData("Order ID", order.orderId)}
        {renderData(
          "Client",
          customers.find((item) => item.id === order.customer).name,
        )}
        {renderData(
          "Warehouse",
          order.splitOrders?.length > 0
            ? "Multi"
            : warehouses.find((item) => item.id === order.warehouse).name,
        )}
        {renderData(
          "Order Date",
          moment(order.orderDate).format("MMM Do YYYY"),
        )}
        {order.trackingNumber &&
          renderData("Tracking Number", order.trackingNumber)}
        {isDelmarTenant(tenant) &&
          order.classification &&
          renderData("Classification", order.classification)}
        {isDelmarTenant(tenant) &&
          renderData("Resizing Order", order.resizingOrder ? "Yes" : "No")}
        {order.orderValue > 0 && renderData("Price", order.orderValue)}
      </div>
      <div className="h-full rounded-lg">
        {renderData(
          "Status",
          <span className="text-sm font-semibold">{order.orderStatus}</span>,
        )}
        {!isBackOrder &&
          renderData(
            "Sub-Status",
            <span className="text-sm font-semibold">{order.subStatus}</span>,
          )}
        {renderData("Multi-Line Order", order.multipleLineItems ? "Yes" : "No")}
        {renderData("Number of Products", order.numberOfProducts)}
        {renderData("Total Quantity", order.totalQuantity)}
        {order.tote && renderData("Tote", order.tote)}
        {order.subTote && renderData("Sub-Tote", order.subTote)}
      </div>
      <div className="h-full rounded-lg">
        {renderData("Source", order.source)}
        {order.reference && renderData("Reference", order.reference)}
        {order.proNumber && renderData("Pro Number", order.proNumber)}
        {order.carrier && renderData("Carrier", order.carrier)}
        {order.shippingRequests?.length > 0 &&
          renderData("Requested Carrier", order.shippingRequests[0].title)}
        {order.deliveryMethod &&
          renderData("Delivery Method", order.deliveryMethod)}
      </div>
    </div>
  );
};

const renderData = (name, value) => {
  return (
    <div className="grid grid-cols-2 p-2">
      <span className="font-bold">{`${name}: `}</span>
      <span className="ml-2">{value}</span>
    </div>
  );
};

export default MetaInformation;
