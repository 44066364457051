import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";

const IntegrationForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedIntegration,
  setSelectedIntegration,
  integrationProviders,
  onSubmit,
  customers,
  multiAccountSupportEnabled,
  shopifyLocations,
  shipstationCarriers,
  isGlobal,
}) => {
  const integrationProvider = integrationProviders?.find(
    (item) => item.name === selectedIntegration.integrationType,
  );
  const multiMarketplaceEnabled =
    multiAccountSupportEnabled && integrationProvider?.multipleAccountSupport;
  return (
    <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
            Integration Details
          </span>
        </div>
        {selectedIntegration.integrationType ===
          "Dynamic Parcel Distribution (DPD)" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Username"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="Password"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
          </>
        )}

        {selectedIntegration.integrationType === "Shopify" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Shopify API Key"
                placeholder="32-character alphanumeric key (e.g. 1234abcd5678efgh91011ijkl1213mnop)"
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="storeName"
                label="Shopify Store Name"
                placeholder="e.g. hopstack.myshopify.com"
                onChange={onChange}
                value={selectedIntegration.storeName}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="Shopify Admin API Access Token"
                placeholder="e.g. shpat_****"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
            <div className="flex-1">
              <label className="mb-2 block text-left">
                <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                  Shopify Locations
                </span>
              </label>
              <Dropdown
                placeholder={"Select Shopify Location"}
                list={shopifyLocations}
                labelKey="name"
                valueKey="locationId"
                name="configurations.enabledShopifyLocationIds"
                setSelected={(e) => {
                  setSelectedIntegration({
                    ...selectedIntegration,
                    configurations: {
                      ...selectedIntegration.configurations,
                      enabledShopifyLocationIds: e,
                    },
                  });
                }}
                selectedValue={
                  selectedIntegration?.configurations?.enabledShopifyLocationIds
                }
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "Shipstation" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Shipstation Token Key"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="Shipstation Token Secret"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
            <div className="flex-1">
              <label className="mb-2 block text-left">
                <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                  Shipstation Carriers
                </span>
              </label>
              <MultiSelectAutoComplete
                options={shipstationCarriers}
                labelKey="name"
                valueKey="code"
                setValues={(e) =>
                  setSelectedIntegration({
                    ...selectedIntegration,
                    configurations: {
                      ...selectedIntegration.configurations,
                      enabledShipstationCarriers: e,
                    },
                  })
                }
                values={
                  selectedIntegration?.configurations
                    ?.enabledShipstationCarriers
                }
                rounded={true}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "ShipCompliant" &&
          isGlobal && (
            <>
              <div>
                <TextField
                  type="text"
                  id="username"
                  nestedkey="connectionDetails"
                  label="Ship Compliant Username"
                  placeholder=" "
                  onChange={onChange}
                  value={selectedIntegration?.connectionDetails?.username}
                />
              </div>
              <div>
                <TextField
                  type="password"
                  id="password"
                  nestedkey="connectionDetails"
                  label="Ship Compliant Password"
                  placeholder=" "
                  onChange={onChange}
                  value={selectedIntegration?.connectionDetails?.password}
                />
              </div>
              <div>
                <TextField
                  type="text"
                  id="shipCompliantPartnerKey"
                  nestedkey="connectionDetails"
                  label="Ship Compliant Partner Key"
                  placeholder=" "
                  onChange={onChange}
                  value={
                    selectedIntegration?.connectionDetails
                      ?.shipCompliantPartnerKey
                  }
                />
              </div>
            </>
          )}
        {selectedIntegration.integrationType === "ShipCompliant" &&
          !isGlobal && (
            <>
              <div>
                <TextField
                  type="text"
                  id="shipCompliantBrandKey"
                  nestedkey="connectionDetails"
                  label="Ship Compliant Brand Key"
                  placeholder=" "
                  onChange={onChange}
                  value={
                    selectedIntegration?.connectionDetails
                      ?.shipCompliantBrandKey
                  }
                />
              </div>
            </>
          )}
        {selectedIntegration.integrationType === "ShipstationEcomm" && (
          <>
            <div>
              <TextField
                type="text"
                id="shipstationEcommAPIKey"
                label="Shipstation API Key"
                nestedkey="connectionDetails"
                placeholder=" "
                onChange={onChange}
                value={
                  selectedIntegration?.connectionDetails?.shipstationEcommAPIKey
                }
              />
            </div>
            <div>
              <TextField
                type="password"
                id="shipstationEcommAPISecret"
                label="Shipstation API Secret"
                nestedkey="connectionDetails"
                placeholder=" "
                onChange={onChange}
                value={
                  selectedIntegration?.connectionDetails
                    ?.shipstationEcommAPISecret
                }
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "LogesTech" && isGlobal && (
          <>
            <div>
              <TextField
                type="text"
                id="logestechBearerToken"
                label="Logestech Bearer Token"
                nestedkey="connectionDetails"
                placeholder="Bearer Token"
                onChange={onChange}
                value={
                  selectedIntegration?.connectionDetatils?.logestechBearerToken
                }
              />
            </div>
            <div>
              <TextField
                type="text"
                id="logestechCompanyId"
                label="Logestech Company Id"
                nestedkey="connectionDetails"
                placeholder="Ex. 000 "
                onChange={onChange}
                value={
                  selectedIntegration?.connectionDetatils?.logestechCompanyId
                }
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "LogesTech" && !isGlobal && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Username"
                placeholder="Ex. Username"
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType !== "NetSuite" &&
          selectedIntegration.integrationType !== "Shipstation" &&
          selectedIntegration.integrationType !==
            "Dynamic Parcel Distribution (DPD)" &&
          selectedIntegration.integrationType !== "CommerceHub" &&
          selectedIntegration.integrationType !== "Shopify" &&
          selectedIntegration.integrationType !== "Amazon" &&
          selectedIntegration.integrationType !== "Salla" &&
          selectedIntegration.integrationType !== "eHub" &&
          selectedIntegration.integrationType !== "Shippo" &&
          selectedIntegration.integrationType !== "PPL" &&
          selectedIntegration.integrationType !== "Magento" &&
          selectedIntegration.integrationType !== "Inventory Planner" &&
          selectedIntegration.integrationType !== "Easypost" &&
          selectedIntegration.integrationType !== "Mirakl" &&
          selectedIntegration.integrationType !== "LogesTech" &&
          selectedIntegration.integrationType !== "Walmart" &&
          selectedIntegration.integrationType !== "ApparelMagic" &&
          selectedIntegration.integrationType !== "SAP-B1-TYM" &&
          selectedIntegration.integrationType !== "Avalara" &&
          selectedIntegration.integrationType !== "NetSuite-RA" &&
          selectedIntegration.integrationType !== "ShipCompliant" &&
          selectedIntegration.integrationType !== "ShipstationEcomm" &&
          selectedIntegration.integrationType !== "Stripe" && (
            <>
              <div>
                <TextField
                  type="text"
                  id="username"
                  label="Client ID"
                  placeholder=" "
                  onChange={onChange}
                  value={selectedIntegration.username}
                />
              </div>
              <div>
                <TextField
                  type="password"
                  id="password"
                  label="Secret Key"
                  placeholder="Password"
                  onChange={onChange}
                  value={selectedIntegration.password}
                />
              </div>
              <div>
                <TextField
                  type="text"
                  id="storeName"
                  label="Token"
                  placeholder=" "
                  onChange={onChange}
                  value={selectedIntegration.storeName}
                />
              </div>
            </>
          )}
        {selectedIntegration.integrationType === "Amazon" &&
          (multiMarketplaceEnabled ? (
            <>
              <div className="space-y-4">
                <div>
                  <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                    Region
                  </span>
                </div>
              </div>
              <div>
                <Dropdown
                  placeholder={"Region"}
                  list={integrationProvider.regions}
                  labelKey="name"
                  valueKey="code"
                  name="region"
                  setSelected={(e) => {
                    setSelectedIntegration({
                      ...selectedIntegration,
                      region: e,
                      marketplaces:
                        e !== selectedIntegration.region
                          ? []
                          : selectedIntegration.marketplaces,
                    });
                  }}
                  selectedValue={selectedIntegration.region}
                />
              </div>
              <div className="flex-1">
                <label className="mb-2 block text-left">
                  <span className="pb-4 font-montserrat text-lg font-medium text-454A4F">
                    Marketplaces
                  </span>
                </label>
                <MultiSelectAutoComplete
                  options={integrationProvider.marketplaces.filter(
                    (marketplace) =>
                      marketplace.region === selectedIntegration.region,
                  )}
                  labelKey="name"
                  valueKey="countryCode"
                  setValues={(e) =>
                    setSelectedIntegration({
                      ...selectedIntegration,
                      marketplaces: e,
                    })
                  }
                  values={selectedIntegration.marketplaces}
                  rounded={true}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <Dropdown
                  placeholder={"Select Region"}
                  list={[
                    { name: "United States", value: "US" },
                    { name: "United Kingdom", value: "UK" },
                    { name: "India", value: "IN" },
                  ]}
                  labelKey="name"
                  valueKey="value"
                  name="marketplaceCountryCode"
                  setSelected={(e) => {
                    onChange({
                      target: {
                        name: "marketplaceCountryCode",
                        id: "marketplaceCountryCode",
                        value: e,
                      },
                    });
                  }}
                  selectedValue={selectedIntegration.marketplaceCountryCode}
                />
              </div>
            </>
          ))}
        {selectedIntegration.integrationType === "NetSuite" && (
          <>
            <div>
              <TextField
                type="text"
                id="accountId"
                nestedkey="netsuiteConnectionDetails"
                label="Account ID"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.accountId}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="consumerKey"
                nestedkey="netsuiteConnectionDetails"
                label="Consumer Key"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.consumerKey}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="consumerSecret"
                nestedkey="netsuiteConnectionDetails"
                label="Consumer Secret"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.consumerSecret}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="tokenId"
                nestedkey="netsuiteConnectionDetails"
                label="NetSuite Token Key"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.tokenId}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="tokenSecret"
                nestedkey="netsuiteConnectionDetails"
                label="NetSuite Token Secret"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.tokenSecret}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="suiteTalkUrl"
                nestedkey="netsuiteConnectionDetails"
                label="SuiteTalk URL"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.suiteTalkUrl}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="restletsUrl"
                nestedkey="netsuiteConnectionDetails"
                label="RESTlet URL"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.restletsUrl}
              />
            </div>
          </>
        )}

        {selectedIntegration.integrationType === "NetSuite-RA" && (
          <>
            <div>
              <TextField
                type="text"
                id="accountId"
                nestedkey="netsuiteConnectionDetails"
                label="Account ID"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.accountId}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="consumerKey"
                nestedkey="netsuiteConnectionDetails"
                label="Consumer Key"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.consumerKey}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="consumerSecret"
                nestedkey="netsuiteConnectionDetails"
                label="Consumer Secret"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.consumerSecret}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="tokenId"
                nestedkey="netsuiteConnectionDetails"
                label="NetSuite Token Key"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.tokenId}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="tokenSecret"
                nestedkey="netsuiteConnectionDetails"
                label="NetSuite Token Secret"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.tokenSecret}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="suiteTalkUrl"
                nestedkey="netsuiteConnectionDetails"
                label="SuiteTalk URL"
                placeholder=" "
                onChange={onChange}
                value={selectedIntegration?.suiteTalkUrl}
              />
            </div>
          </>
        )}

        {selectedIntegration.integrationType === "Salla" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Client ID"
                placeholder=""
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="Secret Key"
                placeholder=""
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="authCode"
                label="Auth Code"
                placeholder=""
                className="placeholder-gray-400"
                onChange={onChange}
                value={selectedIntegration.authCode}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "CommerceHub" && (
          <>
            <div>
              <TextField
                type="text"
                id="commerceHubHost"
                label="Host"
                placeholder=""
                className="placeholder-gray-400"
                onChange={onChange}
                value={selectedIntegration.commerceHubHost}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="username"
                label="Username"
                placeholder=""
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="Password"
                placeholder=""
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "eHub" && (
          <>
            <div>
              <TextField
                type="password"
                id="password"
                label="API key"
                placeholder="API key"
                onChange={onChange}
                value={selectedIntegration.apiKey}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "Shippo" && (
          <>
            <div>
              <TextField
                type="password"
                id="password"
                label="Shippo API Key"
                placeholder="Shippo API Key"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "PPL" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="PPL Client Id"
                placeholder="PPL Client Id"
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="PPL Client Secret"
                placeholder="PPL Client Secret"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "Magento" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Store URL"
                placeholder="https://www.hopstackstore.com"
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="Authorization Token"
                placeholder="32 character long string eg: bd5671caea654461b70db2490e082b6e"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "Inventory Planner" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Account ID"
                placeholder="Eg: z12345"
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="API Key"
                placeholder="64 character long string 8cb9d72f99c8a93c9b68d9f58159be056a34f9b490b3dce6cc8d6ff8b335f027"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "Easypost" && (
          <>
            <div>
              <TextField
                type="password"
                id="password"
                label="Easypost API Key"
                placeholder="Easypost API Key"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "Mirakl" && (
          <>
            <div className="space-y-4">
              <div>
                <TextField
                  type="text"
                  id="username"
                  label="Mirakl Store URL"
                  placeholder="https:/test.mirakl.ca"
                  onChange={onChange}
                  value={selectedIntegration.username}
                />
              </div>
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="Authorization Token"
                placeholder="32 character long string eg: bd5671caea654461b70db2490e082b6e"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
            <div>
              <>Note: Please select same marketplace provided in store URL.</>
              <Dropdown
                placeholder={"Select Marketplace"}
                list={integrationProvider.marketplaces}
                labelKey="name"
                valueKey="name"
                name="marketplace"
                setSelected={(e) => {
                  setSelectedIntegration({
                    ...selectedIntegration,
                    configurations: {
                      ...selectedIntegration.configurations,
                      marketplace: e,
                    },
                  });
                }}
                selectedValue={selectedIntegration?.configurations?.marketplace}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "Walmart" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Client ID"
                placeholder="Eg: sdv34tkher-34db-98dc-234frwefw43"
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="Client Secret"
                placeholder="Eg: WEFbiw7efWGY&EWbkweF&WeFUw7886dfewuEFV-7378ydQEDWu77feq7yveEew"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "SAP-B1-TYM" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Base URL"
                placeholder="Eg: https://test.sapcloud.com:5000/Service1.svc"
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="API Token"
                placeholder="Eg: DJFKENDCKDNDMDKCJJ(=7777D8H"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="storeName"
                label="Warehouse Code (CASE SENSITIVE)"
                placeholder="Please write the warehouse code as it is in SAP-B1"
                onChange={onChange}
                value={selectedIntegration.storeName}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "ApparelMagic" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Apparel Magic Store Domain"
                placeholder="Eg: example"
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="API Token"
                placeholder="Eg: 12 digits alphanumeric"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="apparelMagicWarehouseId"
                label="Apparel Magic Warehouse ID"
                nestedkey="connectionDetails"
                placeholder="Eg: 00"
                onChange={onChange}
                value={
                  selectedIntegration?.connectionDetails
                    ?.apparelMagicWarehouseId
                }
              />
            </div>
            <div>
              <TextField
                type="text"
                id="apparelMagicVendorId"
                nestedkey="connectionDetails"
                label="Apparel Magic Vendor ID"
                placeholder="Eg: 00"
                onChange={onChange}
                value={
                  selectedIntegration?.connectionDetails?.apparelMagicVendorId
                }
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "Avalara" && (
          <>
            <div>
              <TextField
                type="text"
                id="username"
                label="Avalara Username"
                placeholder="Eg: Username"
                onChange={onChange}
                value={selectedIntegration.username}
              />
            </div>
            <div>
              <TextField
                type="password"
                id="password"
                label="Avalara Password"
                placeholder="Eg: Password"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="avalaraCustomerCode"
                nestedkey="configurations"
                label="Avalara Customer Code"
                placeholder="Eg: ABC"
                onChange={onChange}
                value={selectedIntegration?.configurations?.avalaraCustomerCode}
              />
            </div>
          </>
        )}
        {selectedIntegration.integrationType === "Stripe" && (
          <>
            <div>
              <TextField
                type="password"
                id="password"
                label="Stripe Secret Key"
                placeholder="Eg: sk_asdhfbajhsdbfahsdfq23vbqh3ev4"
                onChange={onChange}
                value={selectedIntegration.password}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default IntegrationForm;
