import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import { ChevronDownIcon, ArrowLeftIcon } from "@heroicons/react/outline";

import CustomerSearchBar from "#components/salesManagement/CustomerSearchBar";
import AuditHistory from "#components/salesManagement/AuditHistory";
import SlideOverModal from "#components/common/SlideOverModal";
import { CreateCustomer } from "#components/salesManagement/CreateCustomer";
import CustomerDetails from "#components/salesManagement/CustomerDetails";
import OrderHistoryTable from "#components/salesManagement/OrderHistoryTable";
import CrmOrderHistoryTable from "#components/salesManagement/CrmOrderHistoryTable";

const orderHistoryType = {
  SYSTEM: "system",
  CRM: "crm",
};

const SalesManagement = () => {
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showFullOrderHistory, setShowFullOrderHistory] = useState(false);
  const [selectedOrderHistoryType, setSelectedOrderHistoryType] = useState(
    orderHistoryType.SYSTEM,
  );

  const unselectCustomer = () => setSelectedCustomer(null);
  const hideOrderHistory = () => setShowFullOrderHistory(false);
  const openModal = () => setShowCreateCustomer(true);
  const closeModal = () => setShowCreateCustomer(false);
  const showFullOrderHistoryTable = (type) => {
    setSelectedOrderHistoryType(type);
    setShowFullOrderHistory(true);
  };

  return (
    <>
      <div className="p-8">
        <div className="mb-8 flex items-start justify-between">
          <div>
            {selectedCustomer && !showFullOrderHistory && (
              <PageTitle>
                Customer Details{` > ${selectedCustomer?.hrid}`}
              </PageTitle>
            )}
            {selectedCustomer && showFullOrderHistory && (
              <PageTitle>
                Customer Details
                {` > ${selectedCustomer?.hrid} > Order History`}
              </PageTitle>
            )}
            {!selectedCustomer && (
              <p className="text-gray-600">
                Search and modify customer details from your CRM integration
                here
              </p>
            )}
          </div>
          <div className="flex justify-end">
            {selectedCustomer && !showFullOrderHistory && (
              <button
                onClick={unselectCustomer}
                className="inline-flex w-full cursor-pointer items-center gap-x-1 rounded-lg bg-primaryAccent px-3 py-2 font-medium text-white">
                <ArrowLeftIcon className="h-5 w-5" />
              </button>
            )}
            {selectedCustomer && showFullOrderHistory && (
              <button
                onClick={hideOrderHistory}
                className="inline-flex w-full cursor-pointer items-center gap-x-1 rounded-lg bg-primaryAccent px-3 py-2 font-medium text-white">
                <ArrowLeftIcon className="h-5 w-5" />
              </button>
            )}
            {!selectedCustomer && (
              <button className="inline-flex w-full cursor-pointer items-center gap-x-1 rounded-lg bg-primaryAccent px-3 py-2 font-medium text-white">
                <span>Downloads</span>
                <ChevronDownIcon className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
        {!selectedCustomer && (
          <>
            <CustomerSearchBar
              setShowCreateCustomer={openModal}
              setSelectedCustomer={setSelectedCustomer}
            />
            <AuditHistory />
          </>
        )}
        {selectedCustomer && !showFullOrderHistory && (
          <CustomerDetails
            crmCustomerData={selectedCustomer}
            onShowFullOrderHistory={showFullOrderHistoryTable}
          />
        )}
        {selectedCustomer && showFullOrderHistory && (
          <div className="h-[80vh]">
            {selectedOrderHistoryType === orderHistoryType.SYSTEM && (
              <OrderHistoryTable
                crmCustomerId={selectedCustomer.id}
                full={true}
              />
            )}
            {selectedOrderHistoryType === orderHistoryType.CRM && (
              <CrmOrderHistoryTable
                crmCustomerId={selectedCustomer.id}
                full={true}
              />
            )}
          </div>
        )}
      </div>
      <SlideOverModal
        open={showCreateCustomer}
        onClose={closeModal}
        title={"Add Customer"}>
        <CreateCustomer onCancel={setShowCreateCustomer} />
      </SlideOverModal>
    </>
  );
};

export default SalesManagement;
