import { useEffect, useState, useContext } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { AppStateContext } from "#contexts/appState";
import SlideOverPanel from "#components/common/SlideOverPanel";
import { Tooltip } from "antd";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import OrderHistoryTable from "#components/salesManagement/OrderHistoryTable";
import CrmOrderHistoryTable from "#components/salesManagement/CrmOrderHistoryTable";
import { Country, State } from "country-state-city";
import { useQuery } from "#hooks/useQuery";
import SlideOverModal from "#components/common/SlideOverModal";
import {
  EDIT_CRM_SHIPPING_ADDRESS,
  DELETE_CRM_SHIPPING_ADDRESS,
  EDIT_CRM_ACCOUNT_INFO,
  CREATE_CRM_SHIPPING_ADDRESS,
} from "#mutations/index";
import { CRM_CUSTOMERS } from "#queries";

const ALERT_VISIBILITY_IN_MS = 5000;

const tabsEnums = {
  SHIPPING_ADDRESS: "shippingAddress",
  ORDER: "order",
};

const editEnum = {
  CUSTOMER_DETAILS: "customerDetails",
  CONTACT_DETAILS: "contactDetails",
  CATALOG_DETAILS: "catalogDetails",
  PAYMENT_DETAILS: "paymentDetails",
  SHIPPING_ADDRESS: "shippingAddress",
  ADD_SHIPPING_ADDRESS: "addShippingAddress",
  CREATE_SHIPPING_ADDRESS: "createShippingAddress",
};

const initialAddressInput = {
  country: "US",
};

const orderHistoryType = {
  SYSTEM: "system",
  CRM: "crm",
};

const CustomerDetails = ({ crmCustomerData, onShowFullOrderHistory }) => {
  const appState = useContext(AppStateContext);

  const crmCustomersQuery = useQuery(CRM_CUSTOMERS);
  const editCrmShippingAddressQuery = useQuery(EDIT_CRM_SHIPPING_ADDRESS);
  const deleteCrmCustomerQuery = useQuery(DELETE_CRM_SHIPPING_ADDRESS);
  const editCrmAccountInfo = useQuery(EDIT_CRM_ACCOUNT_INFO);
  const createCrmShippingAddressQuery = useQuery(CREATE_CRM_SHIPPING_ADDRESS);

  const [activeTab, setActiveTab] = useState(tabsEnums.SHIPPING_ADDRESS);
  const [acitveEditSection, setActiveEditSection] = useState(null);
  const [addressInput, setAddressInput] = useState(initialAddressInput);
  const [shippingAddressId, setShippingAddressId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(
    initialAddressInput.country,
  );
  const [selectedState, setSelectedState] = useState("");
  const [filteredStates, setFilteredStates] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(crmCustomerData);
  const [selectedOrderHistoryType, setSelectedOrderHistoryType] = useState(
    orderHistoryType.SYSTEM,
  );
  const [showShippingHistoryPanel, setShowShippingHistoryPanel] =
    useState(false);

  useEffect(() => {
    const updateStates = () => {
      if (selectedCountry) {
        const states = State.getStatesOfCountry(selectedCountry);
        setFilteredStates(states);
        const stateIsValid = states.some(
          (state) => state.isoCode === selectedState,
        );
        if (!stateIsValid) {
          setSelectedState("");
        }
      } else {
        setFilteredStates([]);
        setSelectedState("");
      }
    };

    updateStates();
  }, [selectedCountry]);

  useEffect(() => {
    if (editCrmShippingAddressQuery.error) {
      appState.setAlert(
        editCrmShippingAddressQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (editCrmShippingAddressQuery.data) {
      appState.setAlert(
        editCrmShippingAddressQuery.data.editCrmShippingAddress.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
      closeEditSection();
    }

    if (editCrmShippingAddressQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    editCrmShippingAddressQuery.error,
    editCrmShippingAddressQuery.data,
    editCrmShippingAddressQuery.loading,
  ]);

  useEffect(() => {
    if (editCrmAccountInfo.error) {
      appState.setAlert(
        editCrmAccountInfo.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (editCrmAccountInfo.data) {
      appState.setAlert(
        editCrmAccountInfo.data.editCrmCustomer.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
      closeEditSection();
    }

    if (editCrmAccountInfo.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    editCrmAccountInfo.error,
    editCrmAccountInfo.data,
    editCrmAccountInfo.loading,
  ]);

  useEffect(() => {
    if (deleteCrmCustomerQuery.error) {
      appState.setAlert(
        deleteCrmCustomerQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (deleteCrmCustomerQuery.data) {
      appState.setAlert(
        deleteCrmCustomerQuery.data.deleteCrmShippingAddress.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
    }

    if (deleteCrmCustomerQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    deleteCrmCustomerQuery.error,
    deleteCrmCustomerQuery.data,
    deleteCrmCustomerQuery.loading,
  ]);

  useEffect(() => {
    if (createCrmShippingAddressQuery.error) {
      appState.setAlert(
        createCrmShippingAddressQuery.error.message,
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }

    if (createCrmShippingAddressQuery.data) {
      appState.setAlert(
        createCrmShippingAddressQuery.data.createCrmShippingAddress.message,
        "success",
        ALERT_VISIBILITY_IN_MS,
      );
      crmCustomersQuery.fetchData({
        filters: {
          keyword: selectedCustomer.hrid,
          searchFields: ["Customer_ID"],
          ids: [selectedCustomer.id],
        },
      });
      closeEditSection();
    }

    if (createCrmShippingAddressQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    createCrmShippingAddressQuery.error,
    createCrmShippingAddressQuery.data,
    createCrmShippingAddressQuery.loading,
  ]);

  useEffect(() => {
    if (selectedCustomer && crmCustomersQuery?.data?.crmCustomers) {
      setSelectedCustomer(crmCustomersQuery.data.crmCustomers?.entities[0]);
    }

    if (crmCustomersQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [
    crmCustomersQuery.error,
    crmCustomersQuery.data,
    crmCustomersQuery.loading,
  ]);

  const closeEditSection = () => {
    setActiveEditSection(null);
    // Reset form values
    setAddressInput(initialAddressInput);
  };

  const handleDeleteAddress = (address) => {
    if (activeTab === tabsEnums.SHIPPING_ADDRESS) {
      deleteCrmCustomerQuery.fetchData({
        deleteCrmShippingAddressId: address.id,
        oldCrmShippingAddress: address,
        crmCustomerId: selectedCustomer.hrid,
      });
    }
  };

  const getPopupTitle = () => {
    switch (acitveEditSection) {
      case editEnum.CUSTOMER_DETAILS:
        return "Edit Customer Details";
      case editEnum.CONTACT_DETAILS:
        return "Edit Contact Details";
      case editEnum.CATALOG_DETAILS:
        return "Edit Catalog Details";
      case editEnum.PAYMENT_DETAILS:
        return "Edit Payment Details";
      case editEnum.SHIPPING_ADDRESS:
        return "Edit Shipping Address";
      case editEnum.ADD_SHIPPING_ADDRESS:
        return "Edit Shipping Address";
      case editEnum.CREATE_SHIPPING_ADDRESS:
        return "Add Shipping Address";
      default:
        return "";
    }
  };

  const validateAddress = () => {
    if (!addressInput) {
      return false;
    }

    const { firstName, lastName, line1, state, country, zip, city } =
      addressInput;

    const isValid =
      firstName && lastName && line1 && state && country && zip && city;

    if (!isValid) {
      appState.setAlert(
        "Address entered is invalid.",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
    return isValid;
  };

  const handleCountryChange = (newCountry) => {
    setSelectedCountry(newCountry);
    setSelectedState("");
    setFilteredStates(State.getStatesOfCountry(newCountry));

    setAddressInput((prev) => ({
      ...prev,
      country: newCountry,
    }));
  };

  const handleStateChange = (newState) => {
    setSelectedState(newState);

    setAddressInput((prev) => ({
      ...prev,
      state: newState,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setAddressInput((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleEditAddress = (address) => {
    setAddressInput(address);
    setShippingAddressId(address?.id);
    setActiveEditSection(editEnum.SHIPPING_ADDRESS);
  };

  const popupSaveHandler = () => {
    if (!validateAddress()) {
      return;
    }

    switch (acitveEditSection) {
      case editEnum.CUSTOMER_DETAILS:
      case editEnum.CONTACT_DETAILS:
      case editEnum.CATALOG_DETAILS:
      case editEnum.PAYMENT_DETAILS:
        const { businessName, notes, ...rest } = addressInput;
        const { hrid, ...restDetails } = selectedCustomer;
        let editCrmCustomerInput = {
          ...restDetails,
          accountAddress: rest,
        };
        const oldCrmCustomer = { ...selectedCustomer };
        delete oldCrmCustomer.hrid;
        editCrmAccountInfo.fetchData({
          editCrmCustomerInput,
          oldCrmCustomer,
          crmCustomerId: selectedCustomer.hrid,
        });
        break;
      case editEnum.SHIPPING_ADDRESS:
        const oldCrmShippingAddress = selectedCustomer?.shippingAddresses?.find(
          (address) => address.id === shippingAddressId,
        );
        editCrmShippingAddressQuery.fetchData({
          editCrmShippingAddressInput: {
            ...addressInput,
            id: shippingAddressId,
          },
          oldCrmShippingAddress,
          crmCustomerId: selectedCustomer.hrid,
        });
        break;
      case editEnum.CREATE_SHIPPING_ADDRESS:
        createCrmShippingAddressQuery.fetchData({
          createCrmShippingAddressInput: {
            crmCustomerId: selectedCustomer.id,
            shippingAddress: addressInput,
          },
        });
      default:
    }
  };

  const nameEdit =
    acitveEditSection == editEnum.CONTACT_DETAILS ||
    acitveEditSection == editEnum.CUSTOMER_DETAILS ||
    acitveEditSection == editEnum.SHIPPING_ADDRESS ||
    editEnum.CREATE_SHIPPING_ADDRESS;

  const contactEdit =
    acitveEditSection == editEnum.CONTACT_DETAILS ||
    acitveEditSection == editEnum.SHIPPING_ADDRESS ||
    editEnum.CREATE_SHIPPING_ADDRESS;

  const addressOnlyEdit =
    acitveEditSection == editEnum.SHIPPING_ADDRESS ||
    editEnum.CREATE_SHIPPING_ADDRESS;

  return (
    <div className="w-full flex-col items-center p-5">
      <div className="details-container flex flex-col items-center gap-8">
        <div className="flex w-full flex-col items-center gap-8">
          <div className="grid w-full grid-cols-2 gap-12">
            <div
              className="col-span-1 rounded-md border border-gray-300 p-4"
              style={{ height: "15rem" }}>
              <div className="flex justify-between">
                <div className="flex w-full flex-col items-start gap-1">
                  <p className="text-xl font-semibold text-primaryAccent">
                    {selectedCustomer?.accountAddress?.firstName}{" "}
                    {selectedCustomer?.accountAddress?.lastName}
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    Business Name: {selectedCustomer?.businessName}
                  </p>
                  <p className="text-sm font-medium text-gray-500">
                    Customer ID: {selectedCustomer?.hrid}
                  </p>
                </div>
                <EditButton
                  onClick={() => {
                    setAddressInput(selectedCustomer?.accountAddress);
                    setActiveEditSection(editEnum.CUSTOMER_DETAILS);
                  }}
                />
              </div>
            </div>
            <div
              className="col-span-1 rounded-md border border-gray-300 p-4"
              style={{ height: "15rem" }}>
              <div className="flex justify-between">
                <div className="flex w-full flex-col items-start gap-1">
                  <p className="text-xl font-semibold text-primaryAccent">
                    Contact Details
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    Phone Number:{" "}
                    {selectedCustomer?.accountAddress?.contactNumber || "N/A"}
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    Email Address:{" "}
                    {selectedCustomer?.accontAddress?.email || "N/A"}
                  </p>
                  <p className="mt-5 text-sm font-medium text-gray-900">
                    Mailing Address
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    {selectedCustomer?.accountAddress?.line1 || ""}
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    {selectedCustomer?.accountAddress?.line2 || ""}
                  </p>
                  <p className="text-sm font-medium text-gray-900">
                    {selectedCustomer?.accountAddress?.city || ""},{" "}
                    {selectedCustomer?.accountAddress?.state || ""}
                  </p>
                </div>
                <EditButton
                  onClick={() => {
                    setAddressInput(selectedCustomer?.accountAddress);
                    setActiveEditSection(editEnum.CONTACT_DETAILS);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="details-tab-container flex w-full flex-col items-center py-8">
        <div className="flex w-full border-b border-gray-300">
          <nav className="flex space-x-4" aria-label="Tabs">
            <button
              onClick={() => setActiveTab(tabsEnums.SHIPPING_ADDRESS)}
              className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                activeTab === tabsEnums.SHIPPING_ADDRESS
                  ? "border-224E73 text-224E73"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              }`}>
              Associated Shipping Address Book
            </button>
            <button
              onClick={() => setActiveTab(tabsEnums.ORDER)}
              className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                activeTab === tabsEnums.ORDER
                  ? "border-224E73 text-224E73"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
              }`}>
              Order History
            </button>
          </nav>
        </div>
        {activeTab === tabsEnums.SHIPPING_ADDRESS && (
          <div className="flex w-full flex-col items-center gap-8">
            <div className="flex w-full flex-row items-end justify-between py-4">
              <p className="text-xl font-semibold text-primaryAccent">
                Associated Shipping Address Book
              </p>
              {selectedCustomer?.shippingAddresses?.length > 3 && (
                <p
                  onClick={() => {
                    setShowShippingHistoryPanel(true);
                  }}
                  className="cursor-pointer text-sm font-medium text-primaryAccent">
                  See All Addresses &rarr;
                </p>
              )}
            </div>
            <div className="flex w-full flex-col gap-8">
              {selectedCustomer?.shippingAddresses
                ?.slice(0, 3)
                .map((address, index) => (
                  <div
                    key={index}
                    className="relative border border-gray-300 py-4">
                    <div className="flex items-center">
                      <label
                        htmlFor="address"
                        className="pointer-events-none ml-3 flex-grow">
                        <div className="flex flex-row gap-4 py-2">
                          <p className="text-xl font-medium leading-none text-black">
                            {[address?.firstName, address?.lastName]
                              .filter(Boolean)
                              .join(" ")}
                          </p>
                          <p className="text-sm font-medium text-darkBlue">
                            {address?.plantingZoneCode
                              ? address?.plantingZoneCode
                              : ""}
                          </p>
                        </div>
                        <p className="text-sm text-gray-500">
                          {[
                            address?.line1,
                            address?.line2,
                            address?.city,
                            address?.state,
                            address?.country,
                            address?.zip,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                        <p className="text-sm text-gray-500">
                          {[address?.contactNumber, address?.email]
                            .filter(Boolean)
                            .join(", ")}
                        </p>
                      </label>
                    </div>
                    <div className="absolute right-4 top-1/2 flex -translate-y-1/2 transform space-x-2">
                      <PencilIcon
                        className="h-5 w-5 cursor-pointer"
                        style={{ color: "#224E73" }}
                        onClick={() => {
                          handleEditAddress(address);
                        }}
                      />
                      <TrashIcon
                        className="h-5 w-5 cursor-pointer"
                        style={{ color: "#C93A31" }}
                        onClick={() => {
                          appState.showConfirmation(
                            "Delete",
                            "Are you sure you want to delete this address?",
                            () => {
                              handleDeleteAddress(address);
                              appState.hideConfirmation();
                            },
                            appState.hideConfirmation,
                            "Yes, delete",
                            "No, cancel",
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex w-full justify-start">
              <button
                type="button"
                onClick={() => {
                  setAddressInput(initialAddressInput);
                  setActiveEditSection(editEnum.CREATE_SHIPPING_ADDRESS);
                }}
                className={`cursor-pointer py-2 text-base font-semibold text-primaryAccent underline`}>
                + Add Shipping Address
              </button>
            </div>
          </div>
        )}
        {activeTab === tabsEnums.ORDER && (
          <div className="order-tab-container flex w-full flex-col items-center py-8">
            <div className="flex w-full">
              <nav className="flex space-x-4" aria-label="Tabs">
                <button
                  onClick={() =>
                    setSelectedOrderHistoryType(orderHistoryType.SYSTEM)
                  }
                  className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                    selectedOrderHistoryType === orderHistoryType.SYSTEM
                      ? "border-224E73 text-224E73"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}>
                  System Order History
                </button>
                <button
                  onClick={() =>
                    setSelectedOrderHistoryType(orderHistoryType.CRM)
                  }
                  className={`rounded-t-lg border-b-4 px-3 py-2 text-sm font-medium ${
                    selectedOrderHistoryType === orderHistoryType.CRM
                      ? "border-224E73 text-224E73"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}>
                  CRM Order History
                </button>
              </nav>
            </div>
            {selectedOrderHistoryType === orderHistoryType.SYSTEM && (
              <div className="flex w-full flex-col items-center gap-8">
                <div className={"w-full"} style={{ height: "35rem" }}>
                  <OrderHistoryTable
                    crmCustomerId={selectedCustomer.id}
                    full={false}
                    onShowFull={() =>
                      onShowFullOrderHistory(orderHistoryType.SYSTEM)
                    }
                  />
                </div>
              </div>
            )}
            {selectedOrderHistoryType === orderHistoryType.CRM && (
              <div className="flex w-full flex-col items-center gap-8">
                <div className={"w-full"} style={{ height: "35rem" }}>
                  <CrmOrderHistoryTable
                    crmCustomerId={selectedCustomer.id}
                    full={false}
                    onShowFull={() =>
                      onShowFullOrderHistory(orderHistoryType.CRM)
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {!!acitveEditSection && (
        <SlideOverPanel
          open={!!acitveEditSection}
          setOpen={closeEditSection}
          title={getPopupTitle()}>
          <div className="relative h-full overflow-y-auto bg-white p-4">
            <div className="h-full space-y-1">
              <div className="flex h-full flex-col">
                {/* BusinessName Field */}
                {acitveEditSection == editEnum.CUSTOMER_DETAILS && (
                  <div className="space-y-1 py-2">
                    <label
                      htmlFor="businessName"
                      className="block text-sm font-medium text-gray-700">
                      Business Name
                    </label>
                    <input
                      type="text"
                      name="businessName"
                      id="businessName"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      value={selectedCustomer?.businessName}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </div>
                )}
                <div className="flex gap-2 py-2">
                  {/* First Name Field */}
                  {nameEdit && (
                    <div className="w-1/2 space-y-1">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700">
                        First Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="first-name"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="Doe"
                        value={addressInput?.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                  )}

                  {/* Last Name Field */}
                  {nameEdit && (
                    <div className="w-1/2 space-y-1">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700">
                        Last Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        id="last-name"
                        required
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="John"
                        value={addressInput?.lastName}
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                </div>
                {/* Email Field */}
                {contactEdit && (
                  <div className="space-y-1 py-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder="XYZ123@gmail.com"
                      value={addressInput?.email}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {/* Contact Number Field */}
                {contactEdit && (
                  <div className="space-y-1 py-2">
                    <label
                      htmlFor="contact-number"
                      className="block text-sm font-medium text-gray-700">
                      Contact Number
                    </label>
                    <input
                      type="tel"
                      name="contactNumber"
                      id="contact-number"
                      value={addressInput?.contactNumber}
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder="9876543210"
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {/* Address Fields */}
                {contactEdit && (
                  <div className="space-y-1 py-2">
                    {/* Address Line 1 */}
                    <label
                      htmlFor="address-line1"
                      className="block text-sm font-medium text-gray-700">
                      Address Line 1<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="line1"
                      id="address-line1"
                      required
                      value={addressInput?.line1}
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      onChange={handleInputChange}
                    />
                  </div>
                )}
                {contactEdit && (
                  <div className="space-y-1 py-2">
                    {/* Address Line 2 */}
                    <label
                      htmlFor="address-line2"
                      className="block text-sm font-medium text-gray-700">
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      name="line2"
                      id="address-line2"
                      value={addressInput?.line2}
                      className="mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      onChange={handleInputChange}
                    />
                  </div>
                )}
                {contactEdit && (
                  <div className="grid grid-cols-2 gap-4 space-y-1 py-2">
                    <div>
                      {/* City */}
                      <label
                        htmlFor="address-city"
                        className="block text-sm font-medium text-gray-700">
                        City<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="address-city"
                        value={addressInput?.city}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        onChange={handleInputChange}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="zip-code"
                        className="block text-sm font-medium text-gray-700">
                        Zip Code<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="zip"
                        id="zip-code"
                        required
                        value={addressInput?.zip}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                        placeholder="560075"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}
                {/* State & Country */}
                {contactEdit && (
                  <div className="grid grid-cols-2 gap-4 py-2">
                    <div>
                      <Tooltip
                        title={
                          !selectedCountry
                            ? "Please select a country first"
                            : ""
                        }
                        placement="top">
                        <div style={{ display: "inline-block", width: "100%" }}>
                          <label
                            htmlFor="state"
                            className="block text-sm font-medium text-gray-700">
                            State<span className="text-red-500">*</span>
                          </label>
                          <AutocompleteDropdownV2
                            options={filteredStates}
                            labelKey="name"
                            valueKey="isoCode"
                            onChange={handleStateChange}
                            value={addressInput?.state || ""}
                            placeholder="Select a State"
                            id="name"
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div>
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700">
                        Country<span className="text-red-500">*</span>
                      </label>
                      <AutocompleteDropdownV2
                        options={Country.getAllCountries()}
                        id="name"
                        labelKey="name"
                        valueKey="isoCode"
                        onChange={handleCountryChange}
                        value={addressInput?.country || ""}
                        placeholder="Select a Country"
                      />
                    </div>
                  </div>
                )}

                {/* Planting Zone Checkbox */}
                {addressOnlyEdit && (
                  <div className="flex items-start py-2">
                    <div className="flex h-5 items-center">
                      <input
                        id="planting-zone"
                        name="isPlantingZoneAddress"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-[#224E73] focus:ring-[#224E73]"
                        value={addressInput?.isPlantingZoneAddress}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="isPlantingZoneAddress"
                        className="font-medium text-gray-700">
                        Mark the address as a planting zone
                      </label>
                    </div>
                  </div>
                )}

                {/* Planting Zone Code */}
                {addressOnlyEdit && (
                  <div className="space-y-1 py-2">
                    <label
                      htmlFor="planting-zone"
                      className="block text-sm font-medium text-gray-700">
                      Planting Zone Code
                    </label>
                    <input
                      type="text"
                      name="plantingZoneCode"
                      id="planting-zone"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      placeholder=""
                      value={addressInput?.plantingZoneCode}
                      onChange={handleInputChange}
                    />
                  </div>
                )}
                {/* Notes Field */}
                {acitveEditSection == editEnum.CUSTOMER_DETAILS && (
                  <div className="space-y-1 py-2">
                    <label
                      htmlFor="notes"
                      className="block text-sm font-medium text-gray-700">
                      Notes
                    </label>
                    <input
                      type="text"
                      name="notes"
                      id="notes"
                      className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
                      value={selectedCustomer?.notes}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </div>
                )}
                <div className="mt-auto flex items-center justify-end space-x-4 py-2">
                  <button
                    type="button"
                    onClick={closeEditSection}
                    className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2">
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={popupSaveHandler}
                    disabled={
                      !addressInput?.firstName ||
                      !addressInput?.lastName ||
                      !addressInput?.line1 ||
                      !addressInput?.state ||
                      !addressInput?.country ||
                      !addressInput?.zip ||
                      !addressInput?.city
                    }
                    className="rounded-md border border-transparent bg-[#224E73] px-4 py-2 text-sm font-medium text-white hover:bg-[#224E73] focus:outline-none focus:ring-2 focus:ring-[#224E73] focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SlideOverPanel>
      )}
      <SlideOverModal
        open={showShippingHistoryPanel}
        onClose={() => {
          setShowShippingHistoryPanel(false);
        }}
        title="All Associated Shipping Addresses"
        staticBackdrop={true}>
        <div className="mt-8 flex w-full flex-col gap-8">
          {selectedCustomer?.shippingAddresses?.map((address, index) => (
            <div key={index} className="relative border border-gray-300 py-4">
              <div className="flex items-center">
                <label
                  htmlFor="address"
                  className="pointer-events-none ml-3 flex-grow">
                  <div className="flex flex-row gap-4 py-2">
                    <p className="text-xl font-medium leading-none text-black">
                      {[address?.firstName, address?.lastName]
                        .filter(Boolean)
                        .join(" ")}
                    </p>
                    <p className="text-sm font-medium text-darkBlue">
                      {address?.plantingZoneCode
                        ? address?.plantingZoneCode
                        : ""}
                    </p>
                  </div>
                  <p className="text-sm text-gray-500">
                    {[
                      address?.line1,
                      address?.line2,
                      address?.city,
                      address?.state,
                      address?.country,
                      address?.zip,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </p>
                  <p className="text-sm text-gray-500">
                    {[address?.contactNumber, address?.email]
                      .filter(Boolean)
                      .join(", ")}
                  </p>
                </label>
              </div>
              <div className="absolute right-4 top-1/2 flex -translate-y-1/2 transform space-x-2">
                <PencilIcon
                  className="h-5 w-5 cursor-pointer"
                  style={{ color: "#224E73" }}
                  onClick={() => {
                    handleEditAddress(address);
                  }}
                />
                <TrashIcon
                  className="h-5 w-5 cursor-pointer"
                  style={{ color: "#C93A31" }}
                  onClick={() => {
                    appState.showConfirmation(
                      "Delete",
                      "Are you sure you want to delete this address?",
                      () => {
                        handleDeleteAddress(address);
                        appState.hideConfirmation();
                      },
                      appState.hideConfirmation,
                      "Yes, delete",
                      "No, cancel",
                    );
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </SlideOverModal>
    </div>
  );
};

const EditButton = ({ onClick }) => {
  return (
    <PencilIcon
      className="h-6 w-6 cursor-pointer"
      style={{ color: "#224E73" }}
      onClick={onClick || (() => {})}
    />
  );
};

export default CustomerDetails;
