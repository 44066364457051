import { createContext, useReducer } from "react";

export const GET_METHOD = "GET";
export const PUT_METHOD = "PUT";
export const BULK_UPLOAD_FILE_CONTENT_TYPE = "text/csv";
export const POLLING_TIMER = 2000;
export const EXPIRATION_IN_SECONDS = 300;
export const BULK_UPLOAD_ID_KEY = "BULK_UPLOAD_ID";
export const BULK_UPLOAD_FILE_KEY = "BULK_UPLOAD_FILE";

export const BULK_UPLOAD_FILE_NAMES = {
  WAREHOUSE: "Warehouses",
  PRODUCT: "Products",
};
export const BULK_UPLOAD_ENTITY_TYPES = {
  WAREHOUSE: "warehouse",
  PRODUCT: "product",
};
export const BULK_UPLOAD_KEYS = {
  WAREHOUSE_ID: "WAREHOUSE_ID",
  WAREHOUSE_FILE: "WAREHOUSE_FILE",
  PRODUCT_ID: "PRODUCT_ID",
  PRODUCT_FILE: "PRODUCT_FILE",
};
export const STATUS_ENUM = {
  UPLOADED: "UPLOADED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  QUEUED: "QUEUED",
  NOT_UPLOADED: "NOT_UPLOADED",
  PARTIAL_COMPLETE: "PARTIAL_COMPLETE",
};
export const ACTION_TYPES = [
  {
    name: "Download Template",
    value: "DOWNLOAD_TEMPLATE",
    title: "",
    subTitle: "",
    steps: null,
  },
  {
    name: "Upload Files",
    value: "UPLOAD_FILE",
    title: "Upload File",
    subTitle: "",
    steps: [
      {
        id: "FILE_UPLOAD",
        title: "Upload File",
        subTitle:
          "Please choose the file containing the moduleName information that you would like to upload.",
      },
      {
        id: "MAP_FIELDS",
        title: "Map Fields",
        subTitle:
          "Match your file columns to system fields for accurate data synchronization.",
      },
    ],
  },
  {
    name: "Past Uploads",
    value: "PAST_UPLOADS",
    title: "Past Uploads",
    subTitle:
      "Review the history of your previous file uploads. Check the status of each upload and download any necessary reports, such as error logs or uploaded files. This will have the last 20 uploads.",
    steps: null,
  },
];
export const ACTIONS_TYPE_ENUM = {
  DOWNLOAD_TEMPLATE: "DOWNLOAD_TEMPLATE",
  UPLOAD_FILE: "UPLOAD_FILE",
  PAST_UPLOADS: "PAST_UPLOADS",
};

const BulkUploadEnumns = {
  SET_STEPS: "SET_STEPS",
  SET_SELECTED_STEPS: "SET_SELECTED_STEPS",
  RESET: "RESET",
  LOADING: "LOADING",
  SET_PRESIGNED_URL: "SET_PRESIGNED_URL",
  SET_SELECTE_FILE_DETAILS: "SET_SELECTE_FILE_DETAILS",
  SET_TABLE_DETAILS: "SET_TABLE_DETAILS",
  START_VALIDATION: "START_VALIDATION",
  CHANGE_SELECTED_VALUE: "CHANGE_SELECTED_VALUE",
  SET_ERRORS: "SET_ERRORS",
  SET_PAST_UPLOAD_ENTITIES: "SET_PAST_UPLOAD_ENTITIES",
  MODULE_CONTENT_DETAILS: "MODULE_CONTENT_DETAILS",
};

const initial = {
  loading: false,
  submitBulkUpload: false,
  selectedFile: null,
  fields: [],
  attributes: [],
  defaultValue: [],
  tableRows: [],
  tableHeaders: [],
  tableValues: [],
  allKeys: [],
  error: null,
  selectedStep: null,
  steps: [
    {
      id: "FILE_UPLOAD",
      name: "Upload File",
      href: "#",
      status: "current",
      visible: true,
      isVerify: false,
    },
    {
      id: "MAP_FIELDS",
      name: "Map Fields",
      href: "#",
      status: "upcoming",
      visible: true,
      isVerify: false,
    },
  ],
  mappingFields: null,
  pastUploadsEntities: [],
  bulkUploadFileContentType: "text/csv",
  bulkUploadEntityType: null,
};

const errorsList = [
  "Please select the file before proceeding",
  "Please match the columns before proceeding",
  "Cannot exceed number of spreadsheet columns",
  (item) => {
    return `You can't have multiple ${item}`;
  },
  "Please select the appropriate values",
];

const ModalContext = createContext(initial);

const reducer = (state = initial, action) => {
  const { type, payload } = action;

  switch (type) {
    case BulkUploadEnumns.RESET: {
      return {
        ...state,
        loading: false,
        submitBulkUpload: false,
        selectedFile: null,
        fields: [],
        attributes: [],
        defaultValue: [],
        tableRows: [],
        tableHeaders: [],
        tableValues: [],
        allKeys: [],
        error: null,
        selectedStep: null,
        steps: [
          {
            id: "FILE_UPLOAD",
            name: "Upload File",
            href: "#",
            status: "current",
            visible: true,
            isVerify: false,
          },
          {
            id: "MAP_FIELDS",
            name: "Map Fields",
            href: "#",
            status: "upcoming",
            visible: true,
            isVerify: false,
          },
        ],
        mappingFields: null,
        pastUploadsEntities: [],
        bulkUploadFileContentType: "text/csv",
        bulkUploadEntityType: null,
      };
    }
    case BulkUploadEnumns.LOADING: {
      const { loading } = payload;
      return { ...state, loading: loading };
    }
    case BulkUploadEnumns.SET_STEPS: {
      const { steps } = payload;
      return { ...state, steps: steps };
    }
    case BulkUploadEnumns.SET_SELECTED_STEPS: {
      const { selectedStep } = payload;
      return { ...state, selectedStep: selectedStep };
    }
    case BulkUploadEnumns.SET_PRESIGNED_URL: {
      return { ...state, ...payload };
    }
    case BulkUploadEnumns.SET_SELECTE_FILE_DETAILS: {
      const { dashboardFields, file } = payload;
      if (dashboardFields && file) {
        const updatedFields = Object.keys(dashboardFields).map(
          (key) => dashboardFields[key].display || dashboardFields[key],
        );
        state.selectedFile = file;
        state.error = null;
        state.fields = [...state.fields, ...updatedFields];
        state.defaultValue = [...state.defaultValue, ...updatedFields];
        state.attributes = Object.keys(dashboardFields).slice(
          0,
          state.tableHeaders.length,
        );
        state.allKeys = Object.keys(dashboardFields);
        return { ...state };
      }
      return {
        ...state,
        fields: [],
        defaultValue: [],
        attributes: [],
        allKeys: [],
        selectedFile: null,
        error: null,
      };
    }
    case BulkUploadEnumns.SET_TABLE_DETAILS: {
      const { tableDetails } = payload;
      if (tableDetails && tableDetails?.length !== 0) {
        let tableDetailsList = tableDetails?.filter((row) => row.length > 1);
        state.tableHeaders = tableDetailsList[0].filter((item) => item);
        state.tableRows = tableDetailsList.slice(1);
        return { ...state };
      }
      return {
        ...state,
        tableHeaders: [],
        tableRows: [],
      };
    }
    case BulkUploadEnumns.SET_ERRORS: {
      return { ...state, ...payload };
    }
    case BulkUploadEnumns.START_VALIDATION: {
      const { dashboardFields } = payload;
      if (state.attributes.length === state.tableHeaders.length) {
        const duplicateDefaultValue = state?.defaultValue.filter(
          (header, index) => state?.defaultValue?.indexOf(header) !== index,
        );
        if (duplicateDefaultValue && duplicateDefaultValue?.length !== 0) {
          return {
            ...state,
            error: errorsList[3](duplicateDefaultValue[0]),
            mappingFields: null,
            submitBulkUpload: false,
          };
        }
        const mappingFields = {};
        if (state.attributes.length === state.defaultValue.length) {
          for (let j = 0; j < state.defaultValue.length; j++) {
            const value = state?.defaultValue[j];
            const dashboardFieldskey =
              dashboardFields && value
                ? Object.keys(dashboardFields).find((key) => {
                    if (
                      dashboardFields[key] &&
                      typeof dashboardFields[key] === "object"
                    ) {
                      return dashboardFields[key]["display"] === value;
                    } else {
                      return dashboardFields[key] === value;
                    }
                  })
                : null;
            if (dashboardFieldskey) {
              mappingFields[dashboardFieldskey] = state?.tableHeaders[j];
            }
          }
        }
        return {
          ...state,
          mappingFields: mappingFields,
          submitBulkUpload: true,
          error: null,
        };
      } else {
        return { ...state, error: errorsList[1] };
      }
    }
    case BulkUploadEnumns.CHANGE_SELECTED_VALUE: {
      const { value, index } = payload;
      for (let i = 0; i < state.defaultValue.length; i++) {
        if (index === i) {
          state.defaultValue[i] = value;
          return { ...state, error: null };
        } else {
          continue;
        }
      }
    }
    case BulkUploadEnumns.SET_PAST_UPLOAD_ENTITIES: {
      const { pastUploadsEntities } = payload;
      return {
        ...state,
        pastUploadsEntities: [...pastUploadsEntities],
      };
    }
    case BulkUploadEnumns.MODULE_CONTENT_DETAILS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

function BulkUploadV2ModalProvider(props) {
  const [state, dispatch] = useReducer(reducer, initial);

  function setLoading(loading) {
    dispatch({ type: BulkUploadEnumns.LOADING, payload: { loading } });
  }
  function setSteps(steps) {
    dispatch({ type: BulkUploadEnumns.SET_STEPS, payload: { steps } });
  }
  function setSelectedStep(selectedStep) {
    dispatch({
      type: BulkUploadEnumns.SET_SELECTED_STEPS,
      payload: { selectedStep },
    });
  }
  function resetModal() {
    dispatch({ type: BulkUploadEnumns.RESET });
  }
  function setSelectedFileDetails(file, dashboardFields = null) {
    dispatch({
      type: BulkUploadEnumns.SET_SELECTE_FILE_DETAILS,
      payload: { file, dashboardFields },
    });
  }
  function setTableDetails(tableDetails) {
    dispatch({
      type: BulkUploadEnumns.SET_TABLE_DETAILS,
      payload: { tableDetails },
    });
  }
  function setErrors(error) {
    dispatch({ type: BulkUploadEnumns.SET_ERRORS, payload: { error } });
  }
  function changeselectedValue(value, index) {
    dispatch({
      type: BulkUploadEnumns.CHANGE_SELECTED_VALUE,
      payload: { value, index },
    });
  }
  function startValidation(payload) {
    dispatch({
      type: BulkUploadEnumns.START_VALIDATION,
      payload,
    });
  }
  function setPastUploadsEntities(payload) {
    dispatch({
      type: BulkUploadEnumns.SET_PAST_UPLOAD_ENTITIES,
      payload,
    });
  }
  function setModuleContentDetails(payload) {
    dispatch({
      type: BulkUploadEnumns.MODULE_CONTENT_DETAILS,
      payload,
    });
  }

  return (
    <ModalContext.Provider
      value={{
        ...state,
        setLoading,
        setSteps,
        setSelectedStep,
        resetModal,
        setSelectedFileDetails,
        setTableDetails,
        setErrors,
        changeselectedValue,
        startValidation,
        setPastUploadsEntities,
        setModuleContentDetails,
      }}
      {...props}
    />
  );
}

export { ModalContext, BulkUploadV2ModalProvider };
